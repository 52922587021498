import { CountryFilterPolicies } from "./components/country-filter-policies";
import { WebsiteHeader } from "./components/website-header";
import { WebsiteFooter } from "./components/website-footer";
import { SUPPORTED_COUNTRIES } from "./utils/functions";

export function TermsConditions() {
  const qParameters = new URLSearchParams(window.location.search);
  const countryCode = qParameters.get("country") || SUPPORTED_COUNTRIES.GB;

  return (
    <>
      <div className=''>
        <div className='trade-uk-landing__intro'>
          <WebsiteHeader />
        </div>
        <div className='trade-uk-terms'>
          <p className='trade-uk-terms__title'>Terms & Conditions</p>
          <div className='container'>
            <CountryFilterPolicies countryCode={countryCode} page='terms' />
            {countryCode === SUPPORTED_COUNTRIES.NG ? <NGTerms /> : <UKTerms />}
          </div>
        </div>
        <WebsiteFooter isColored={true} countryCode={countryCode} />
      </div>
    </>
  );
}

const NGTerms = () => {
  return (
    <div className='trade-uk-terms__content'>
      <p>
        This Terms of Use <b>(“Terms of Use”)</b> is a legal agreement between
        you
        <b> (“You” /“Your”/ “TradeDepot Retailer”)</b>, and TradeDepot{" "}
        <b>(“TradeDepot” “Us” “Our” “We”)</b>. This Terms of Use (together with
        the Cash Advance Terms of Use and our Privacy Policy for the App,
        referred to as the
        <b> “Related Agreements”</b>) sets out the complete terms and conditions
        upon which You may use the TradeDepot Wholesale application software
        (the <b>“App”</b>) and website hosted at{" "}
        <a href='https://tradedepot.co' target='_blank' rel='noreferrer'>
          https://tradedepot.co
        </a>{" "}
        (the <b>“Website”</b>).
        <br /> <br />
        By signing up to create a profile{" "}
        <b>(“TradeDepot Wholesale Profile”)</b>, You acknowledge that You have
        read, understood, and agree to be bound by this Terms of Use, and the
        Related Agreements, where applicable. You also agree to comply with all
        agreements, applicable laws, and regulations incorporated into this
        Terms of Use. The provisions of this Terms of Use and the Related
        Agreements govern Your use of the App, and the business relationship
        between You and TradeDepot. If You do not accept the terms of this
        Agreement and other Related Agreements, do not access or use this site
        or the services.
        <br /> <br />
        The App allows a TradeDepot Retailer to purchase a stock of Consumer
        Goods (the <b>“Products/Goods”</b>). The App also allows TradeDepot
        Retailers to request and obtain cash advances for the sole purpose of
        purchasing Products on the App or from registered TradeDepot Wholesale
        pay partners <b>(“Pay Partners”)</b> collectively referred to as{" "}
        <b>(“Services”)</b>. Any new features or tools which are added to the
        Services shall be also subject to the Terms of Use.
        <br />
        <br />
        TradeDepot reserves the right to amend this Terms of Use and the Related
        Agreements at any time and will notify You of any such changes by
        posting the revised terms of use on the App. All changes shall be
        effective upon posting. You should check this Terms of Use and the
        Related Agreements periodically for such changes. Your continued use of
        the App after any such changes to this Terms of Use and Related
        Agreements constitutes Your agreement to be bound by any changes.
        TradeDepot may terminate, suspend, change, or restrict access to all or
        any part of the Services without notice or liability.
      </p>
      <br />
      <p>
        You acknowledge and agree that Your use of the Services, including
        information transmitted to or stored by TradeDepot, is governed by Our
        Privacy Policy.
      </p>
      <h3>1. TRADEDEPOT Wholesale PROFILE TERMS</h3>
      <p>
        1.1. You hereby declare that You are a human being over 18 years of age
        and of a sound mind, or at least the age of majority in the jurisdiction
        where You reside and from which You use the Services. Your TradeDepot
        Wholesale Profile may be deleted without warning, if We believe, in Our
        sole discretion, that You are less than 18 years of age.
        <br />
        <br />
        1.2. In order to create a TradeDepot Wholesale Profile and access the
        Services, You would be required to download and register through the
        App. Alternatively, You may sign up for a TradeDepot Wholesale Profile
        by registering through a verified TradeDepot Wholesale field
        representative <b>(Verified Representative)</b>.
        <br />
        <br />
        1.3. n order to create a TradeDepot Wholesale Profile on the App or
        through a Verified Representative, You would be required to provide the
        following: Your first name, last name, phone number, business name,
        password, business address, date of birth, and any other information
        indicated as required or that We may ask from You from time to time{" "}
        <b>(“Information”)</b>.
        <br />
        <br />
        1.4. When You create a TradeDepot Wholesale Profile, You and Your
        employers or principals will be deemed as the contracting party and as a
        TradeDepot Retailer for the purposes of this Terms of Use.
        <br />
        <br />
        1.5. We reserve the right to deactivate Your TradeDepot Wholesale
        Profile, on suspicion of illegal activities or other activities that
        directly or indirectly breaches the provisions of this Terms of Use and
        the Related Agreements or for no reason at all, in Our sole discretion.
        <br />
        <br />
        1.6. You acknowledge that TradeDepot will use the phone number You
        provided to Us when You created Your TradeDepot Wholesale Profile or as
        updated by You from time to time as the primary mode of communicating
        with You. You must monitor the TradeDepot Wholesale Profile phone number
        You provided to TradeDepot. Your TradeDepot Wholesale Profile phone
        number must be capable of making calls and receiving calls. Your
        communications with TradeDepot can only be authenticated if they come
        from Your TradeDepot Wholesale Profile phone number.
        <br />
        <br />
        1.7. As part of Our security procedures, You determine Your sign-up
        details or any Information You input on the App. By signing up for a
        TradeDepot Wholesale Profile, You agree to treat Your sign-in details as
        confidential and not to disclose those details to any third party.
        TradeDepot does not have access to Your sign-in details, if You forget
        Your sign-in details or know or suspect that a third party has gained
        access to Your sign-in-details, You must promptly change Your sign-in
        details, by resetting Your password or by contacting Us to place a
        restriction on Your TradeDepot Wholesale Profile.
        <br />
        <br />
        1.8. You confirm that all information You provide to Us is true, correct
        and not misleading. You agree to provide any additional documents and/or
        information as may be required from time to time. In the event that the
        Information You provided to Us or a Verified Representative change, You
        hereby agree to notify Us of such changes immediately.
        <br />
        <br />
        1.9. You consent to Us collecting and using technical information about
        the App and related software, hardware and peripherals to improve Our
        products and to provide any other services to You. If You use the
        Services, You consent to Us and Our affiliates' and licensees' to the
        processing, the transmission, collection, retention, maintenance, and
        use of Your personal data to improve Our Services and/or Your experience
        while using the App.
        <br />
        <br />
        1.10. By accessing this App, You hereby authorize Us to provide the
        Services herein contemplated using the App.
      </p>
      <h3>2. TradeDepot Wholesale KYC AND PROFILE ACTIVATION </h3>
      <p>
        2.1. Your TradeDepot Wholesale Profile will be active as soon as We are
        able to verify Your Information, and where necessary pay a visit to the
        address provided by You while signing up on the App.
      </p>
      <h3>3. TRADEDEPOT Wholesale WALLET</h3>
      <p>
        3.1. Your TradeDepot Wholesale Profile (the “TradeDepot Wholesale
        Profile”) is accompanied by a unique digital TradeDepot Wholesale wallet
        (the “TradeDepot Wholesale Wallet”). The unique account number for Your
        TradeDepot Wholesale Wallet is displayed on the “Add Money” tab on the
        App. You must add Your preferred bank account on the App.
        <br />
        <br />
        3.2. You would be able to fund Your TradeDepot Wholesale Wallet by:
        <br />
        (i) Funding Your TradeDepot Wholesale Wallet through electronic
        transfer;
        <br />
        (ii) Funding Your TradeDepot Wholesale Wallet using the Unstructured
        Supplementary Service Data (USSD) code displayed on the App; and
        <br />
        (iii)visiting the nearest mobile money agent or bank to deposit money
        into Your TradeDepot Wholesale Wallet through your unique account
        number.
        <br />
        <br />
        3.3. Please note that while accessing the Services, standard/approved
        banking charges may apply. It is Your responsibility to pay all fees, or
        charges as may be required from time to time.
        <br />
        <br />
        3.4. f there is a challenge with processing any amounts due to us
        through Your TradeDepot Wholesale Wallet, We may charge any other valid
        payment method associated with Your TradeDepot Wholesale Profile.
        <br />
        <br />
        3.5. Please note that We may share Your Information with third parties
        to the extent required to provide the Services and for the efficient
        performance of the App.
      </p>
      <h3>4. TRADEDEPOT WHOLESALE WALLET RESTRICTIONS</h3>
      <p>
        4.1. The TradeDepot Wholesale Wallet is provided to You for the sole
        purpose of the Services. You shall not attempt to make transfers out of
        Your TradeDepot Wholesale Wallet in a manner contrary to the provisions
        of this Terms of Use.
        <br />
        <br />
        4.2. In the event there is a reasonable cause, TradeDepot shall have the
        right in its sole and absolute discretion to place restrictions on Your
        TradeDepot Wholesale Wallet and limit or prevent You from using the
        funds in Your TradeDepot Wholesale Wallet.
        <br />
        <br />
        4.3. You are expressly forbidden from;
        <br />
        <br />
        4.3.1. Using the funds in Your TradeDepot Wholesale Wallet to pay for
        products other than the Products contemplated in this Terms of Use.
        <br />
        <br />
        4.3.2. Using any TradeDepot or third-party content made available
        through Your TradeDepot Wholesale Wallet for activities not contemplated
        in this Terms of Use and the Related Agreements. You agree not to use,
        copy, display, distribute, modify, broadcast, translate, reproduce,
        reverse engineer, reformat, incorporate into advertisements and other
        works, sell, trade in, promote, create derivative works, or in any way
        exploit or allow others to exploit any part of the TradeDepot Wholesale
        Wallet or the App
        <br />
        <br />
        4.3.3. Using the TradeDepot Wholesale Wallet or the App for any
        fraudulent, malicious or unlawful purposes or in any manner inconsistent
        with this Terms of Use.
        <br />
        <br />
        4.4. ou hereby agree to fully and unconditionally cooperate with
        TradeDepot and relevant agencies in any investigation conducted by
        TradeDepot or a governmental or regulatory body in connection with Your
        TradeDepot Wholesale Wallet or your use of the App.
      </p>
      <h3>5. TradeDepot Wholesale PURCHASES AND PAYMENTS</h3>
      <p>
        5.1. There is a minimum order requirement (excluding delivery charges)
        for every order placed which would be disclosed to You at the time of
        Your purchase (<b>the “Minimum Order Requirement”</b>).
        <br />
        <br />
        5.2. Products featured on the App are subject to availability and
        prevailing market conditions. In the event of non-availability of any
        Products you order, You may browse the App for a list of Pay Partners
        near You or You may contact a Verified Representative on 08005550555 to
        assist You with locating a Pay Partner closest to You, from whom You may
        purchase Products from. We reserve the right to discontinue the sale of
        any Product featured on the App at any time, and our only liability in
        such cases will be limited to a refund of any money paid to Us for
        Products which We may no longer be able to deliver to You (after any
        transfer charges).
        <br />
        <br />
        5.3. To purchase Products on the App, You would be required to select
        the Products You wish to purchase, the quantity of the Products and then
        add the selected Products to Your cart and click “Proceed to checkout”
        (“<b>Offer</b>”). You will be guided through the "checkout" process by a
        series of instructions. If You want to correct any errors You have made,
        You can use the functionality on the App to go back and remove Products
        from Your order or empty Your cart and start the Offer process all over
        again. Alternatively, You may contact a Verified Representative on
        08005550555 to make an Offer by providing the Verified Representative
        with information about the Products and the quantity of the Products You
        wish to purchase (“<b>Telesales Offer</b>”).
        <br />
        <br />
        5.4. Upon making an Offer, You may be required to pay for the Products
        with funds in Your TradeDepot Wholesale Wallet or to pay cash on
        delivery (if your profile allows it) of the Products to You. You may
        also utilize credit if your profile allows it.
        <br />
        <br />
        5.5. Your Offer or Telesales Offer is deemed accepted when You opt to
        pay for the Products on delivery of the Products to You or when You opt
        to pay for the Products through Your TradeDepot Wholesale Wallet or by
        credit and a complete payment is fully authorized by the payment gateway
        (“<b>Acceptance</b>”).
        <br />
        <br />
        5.6. By opting to pay for the Products through Your TradeDepot Wholesale
        Wallet, You hereby authorize that automatic debit deductions be made in
        favor of TradeDepot from Your TradeDepot Wholesale Wallet for the price
        of the Products, applicable taxes, standard approved bank charges, and
        any fees incurred in relation to shipping of the Products to You.
        <br />
        <br />
        5.7. We reserve the right to adjust prices, Products and special offers
        at our discretion. Deals, gift coupons and vouchers and other related
        promotions (“Promotions”) are exclusive and cannot be combined and may
        not be exchanged against for cash. TradeDepot reserves the right to
        discontinue Promotions at any time without any prior notice.
        <br />
        <br />
        5.8. The following shall apply to a TradeDepot Retailer who purchases
        Products from a Pay Partner:
        <br />
        <br />
        5.8.1. You shall identify yourself as a TradeDepot Retailer to enable a
        Pay Partner verify Your identity.
        <br />
        <br />
        5.8.2. After a Pay Partner has verified Your Identity You may proceed to
        shop for Products.
        <br />
        <br />
        5.8.3. As soon as You are done selecting Products You wish to buy, You
        shall present the Products to a Pay Partner.
        <br />
        <br />
        5.8.4. A Pay Partner would calculate the cost of the Products You
        selected and input the total amount of the Products in the “amount”
        field on the Pay Partner’s app.
        <br />
        <br />
        5.8.5. You are required to confirm the accuracy of the total amount
        charged by a Pay Partner and thereafter proceed to make payment using
        the relevant payment and validation options available.
        <br />
        <br />
        5.8.6. The total amount charged by a Pay Partner would be debited from
        Your TradeDepot Wholesale Wallet or your credit.
        <br />
        <br />
        5.8.7. Please also note that standard/approved banking charges shall
        apply to debit deductions made from Your TradeDepot Wholesale Wallet.
        <br />
        <br />
        5.8.8. TradeDepot bears no liability for the price and/or quality of
        Products purchased from Pay Partners or from the App.
        <br />
        <br />
        5.9. By authorizing payment for Products You bought from a Pay Partner
        You hereby authorize that automatic debit deductions be made in favor of
        TradeDepot from Your TradeDepot Wholesale Wallet, for the price of the
        Products, including applicable taxes, standard approved bank charge and
        any fees incurred in relation to shipping of the Products to You.
      </p>
      <h3>6. RISK, DELIVERY TERMS AND OTHER FEES</h3>
      <p>
        6.1. Risk of loss and damage of Products passes to You at the time You
        pick up the Products or at the time the Products are delivered to You or
        are left with a person or in a place nominated by You (“<b>Delivery</b>
        ”). We shall not be responsible for any loss or damage caused to the
        Products after Delivery.
        <br />
        <br />
        6.2. All Deliveries are subject to delivery charges as communicated to
        You by Us.
        <br />
        <br />
        6.3. We use reasonable efforts to ensure that Your Products are
        delivered on the delivery date provided in Your delivery confirmation
        message. However, please note the delivery dates provided by Us are only
        Our best estimates and the actual delivery of Your Products may be later
        than the date provided. TradeDepot gives no guarantee or undertaking as
        to dates of dispatch or Delivery and shall accept no liability for any
        loss resulting to You from late Delivery of the Products.
        <br />
        <br />
        6.4. In the event that Products are returned undelivered or refused at
        the time of Delivery, You hereby agree that We reserve the right to
        charge the full delivery charges for the back and forth movements of the
        Products and any other applicable fee and transaction charges.
        <br />
        <br />
        6.5. Please note that Products featured on the App are for illustration
        purposes only. Upon pickup or delivery of the Products, The Products may
        look different than is shown in the image. TradeDepot bears no liability
        for differences in shape, color, or size of the Products.
        <br />
        <br />
        6.6. You hereby agree that We are not liable for the quality of the
        Products delivered to You such as, but not limited to, taste, texture,
        colour etc. While We are willing to escalate any Product-related
        complaint to the manufacturer, We are not responsible for the resolution
        of such complaints.
        <br />
        <br />
        6.7. You hereby agree and give Your consent to the sharing of Your
        Information with third parties to the extent required to provide the
        Services, where we consider necessary to protect and/or advance our
        legitimate interests and for the efficient performance of the App.
      </p>
      <h3>7. CASH ADVANCES</h3>
      <p>
        7.1. If You are interested in obtaining cash advances from TradeDepot,
        please refer to the Cash Advance Terms & Conditions in this Agreement..
      </p>
      <h3>8. OBLIGATIONS OF A TRADEDEPOT RETAILER</h3>
      <p>
        8.1. You shall be responsible for ensuring the proper performance of any
        electronic or other device used to connect to the Services. TradeDepot
        shall neither be responsible for any errors or failures caused by any
        malfunction of Your device, nor shall TradeDepot be responsible for any
        computer virus or related problems that may be associated with the use
        of the App and the device. You shall be responsible for charges due to
        any service provider providing You with internet connection, and
        TradeDepot shall not be responsible for losses or delays caused by lack
        of internet connection.
        <br />
        <br />
        8.2. You shall follow all instructions, procedures and terms contained
        in this Terms of Use and any document provided by TradeDepot concerning
        the use of the App.
        <br />
        <br />
        8.3. You shall immediately inform TradeDepot in the event that You have
        reason to believe that unauthorized use of the App has or may have
        occurred or could occur and a transaction may have been fraudulently
        initiated or compromised.
        <br />
        <br />
        8.4. You shall at all times follow the security procedures notified to
        You by TradeDepot from time to time or such other procedures as may be
        applicable to the 8 Services from time to time. In particular, You shall
        ensure that the Services are not used by anyone other than You.
        <br />
        <br />
        8.5. You shall not at any time operate or use the App in any manner that
        may be prejudicial to TradeDepot.
      </p>
      <h3>9. ACCEPTABLE USE RESTRICTIONS</h3>
      <p>
        9.1. You are granted a non-exclusive, non-transferable, personal,
        limited license to use the App for the purposes contemplated herein.
        Your limited right to use the App is revocable at Our discretion.
        <br />
        <br />
        9.1.1. You shall not use the App in any unlawful manner, for any
        unlawful purpose, or in any manner inconsistent with this Terms of Use,
        or act fraudulently or maliciously.
        <br />
        <br />
        9.1.2. You shall not connive with TradeDepot’s employees, Pay Partners,
        other TradeDepot Retailers and third parties to use the App or the
        Services to defraud TradeDepot.
        <br />
        <br />
        9.1.3. You shall not use the Cash Advance obtained from TradeDepot to
        make payments for products, other than the Products contemplated in this
        Agreement.
        <br />
        <br />
        9.1.4. You shall not create multiple TradeDepot Wholesale Profiles or
        operate multiple TradeDepot Wholesale Profiles through proxies. You and
        Your business must be associated with only one TradeDepot Wholesale
        Profile at all times.
        <br />
        <br />
        9.1.5. You shall not use the App or the Services and any Cash Advance
        obtained from TradeDepot to make payments to Pay Partners or other third
        parties for any debts, borrowing or any other transactions other than
        the purchase of Products contemplated in this Agreement.
        <br />
        <br />
        9.1.6. You shall not register as a Pay Partner if you are a retailer
        and/or in addition, for the purpose of defrauding TradeDepot or for the
        purpose of using the App/Services for any unlawful purpose, or in any
        manner inconsistent with this Terms of Use and the Related Agreements.
        <br />
        <br />
        9.1.7. You shall not use the App/Services to commit identity theft;
        neither shall You connive with friends, families and other third parties
        to defraud TradeDepot.
        <br />
        <br />
        9.1.8. You shall not willfully destroy or deactivate the electronic
        devices, email address, SIM card associated with Your Shop Top Up
        Profile or carry out other similar activities inconsistent with Your
        obligations under this Terms of Use
        <br />
        <br />
        9.1.9. You shall not use the App in a manner that imply or promote
        support or funding of, or membership in, a terrorist organization.
        <br />
        <br />
        9.1.10. You shall not infringe Our intellectual property rights or those
        of any third party in relation to Your use of the App, including the
        submission of any material (to the extent that such use is not licensed
        by this Terms of Use or the Related Agreements.
        <br />
        <br />
        9.1.11. You shall not use the App or the Services in a way that could
        damage, disable, overburden, impair or compromise Our systems or
        security or interfere with other users.
        <br />
        <br />
        9.1.12. You shall not use the Services to transmit malware or host
        phishing pages. You shall not perform activities that harm or disrupt
        the operation of the Services or any of Our other infrastructure, or of
        others, including Our third-party providers. You shall not use the
        Services for deceptive commercial practices or any other illegal or
        deceptive activities
        <br />
        <br />
        9.1.13. You shall not extract any information or data from the Services
        or Our systems or attempt to decipher any transmissions to or from the
        servers running the Services.
        <br />
        <br />
        9.1.14. You shall not use the App or the Services in a manner that
        contravenes or that facilitate or promote activities that contravene
        applicable laws or this Terms of Use.
        <br />
        <br />
        9.1.15. We may, at any time and without notice, deactivate Your
        TradeDepot Wholesale Profile or Your access to the Services if You
        engage in activities that violate clauses 9.1 to 9.1.14, including
        activities outside of Your use of the Services and take legal action
        against You.
        <br />
        <br />
        9.2. Without prejudice to clauses 14.1 to 14.2.4 herein, You hereby
        agree to indemnify TradeDepot (and its directors, officers, affiliates,
        agents, successors and assigns against all claims, liabilities damages,
        costs and expenses (including attorney fees and related charges) for any
        breach of the provisions specified in clauses 9.1 to 9.1.14 of this
        Terms of Use.
        <br />
        <br />
        9.3. TradeDepot shall have the right, but not the obligation, to monitor
        or investigate Your use of the Services at any time for compliance with
        this Terms of Use and the Related Agreements. Our determination of
        whether a breach of this Terms of Use has occurred will be final and
        binding, and any action taken with respect to enforcing the provisions
        specified in this Terms of Use, including taking no action at all, will
        be at Our sole discretion.
        <br />
        <br />
        9.4. TradeDepot may modify the provisions of this Terms of Use at any
        time in its sole discretion. By continuing to use the Services or access
        Your TradeDepot Wholesale Profile after the terms specified in clauses
        9.1 to 9.4, has been modified, You agree to be bound by the modified
        terms.
        <br />
        <br />
        9.5 If You suspect that TradeDepot Retailers, Pay Partners, staff of
        TradeDepot or other third parties are in breach of clauses 9.1 to
        9.1.14, or this Agreement, please contact Us via the App, a toll-free
        line on <a href='tel:+2348005550555'>08005550555</a>, or you can send us
        an e- mail via{" "}
        <a href='mailto:enquiries@TradeDepot.co'>enquiries@TradeDepot.co</a>.
      </p>
      <h3>10. INTELLECTUAL PROPERTY RIGHTS & CONFIDENTIALITY</h3>
      <p>
        10.1. Notwithstanding any other provision in this Terms of Use,
        TradeDepot shall remain the exclusive owner of the copyrights, and any
        other intellectual property rights in the App and all related
        technology, anywhere in the world. You fully acknowledge that You have
        no rights in, or to, the App and all related technology other than the
        license we have granted to You herein, to use the App in accordance with
        the provisions specified in this Terms of Use. You acknowledge that You
        have no right to have access to the App in source-code form.
        Intellectual property, as used in this Terms of Use, means all{" "}
        <i>
          patents, rights to inventions, utility models, copyright and related
          rights, trademarks, service marks, trade, business and domain names,
          rights in trade dress or get-ups, rights in goodwill or to sue for
          passing off, unfair competition rights, rights in designs, rights in
          computer software, database right, topography rights, moral rights,
          rights in confidential information including know how and trade
          secrets and any other intellectual property rights in each case
          whether registered or unregistered and including all applications for
          and renewals or extensions of such rights and all similar or
          equivalent rights or forms of protection in any part of the world and
          intellectual property right means any one of the intellectual property
          rights as the context so requires
        </i>
        .
        <br />
        <br />
        10.2. You hereby agree not to reproduce, duplicate, copy, sell, resell
        or exploit any portion of the Services. You hereby undertake not
        disclose any information associated with Our business including specific
        business information, technical processes and formulas, software,
        customer lists, prospective customer lists, names, addresses and other
        information regarding customers and prospective customers, product
        designs, sales, costs (including any relevant processing fees), price
        lists, and other unpublished financial information, business plans and
        marketing data, (“<b>Confidential information</b>”) to any third party
        without the prior written consent of TradeDepot. Confidential
        Information shall include, but shall not be limited to, any and all
        confidential and proprietary information, whether or not marked as
        confidential or proprietary. Our Confidential Information includes all
        information that You receive relating to Us or to the Services that is
        not known to the general public including information related to Our
        security program and practices.
      </p>
      <h3>11. PROCESSING OF PERSONAL DATA</h3>
      <p>
        11.1. Any Personal Data that comes into Your possession while performing
        Your obligations under this Terms of Use must be processed in accordance
        with the Nigeria Data Protection Act 2023 or any amendments thereto.
        <br />
        <br />
        11.2. You must have in place all necessary and appropriate consents and
        notices in place to enable, where necessary, lawful transfer of Personal
        Data to Us for the duration and purposes of this Terms of Use.
        <br />
        <br />
        11.3. By using this App, You consent to Us, sharing the personal and
        other data provided by You in creating Your TradeDepot Wholesale Profile
        with third party contractors for the purpose of enabling efficient
        performance of the Services, ensuring the Cash Advance You obtained from
        TradeDepot is repaid, preventing fraud and effecting any payments or
        notifications that We need to make to You.
        <br />
        <br />
        11.4. You shall ensure that You have in place appropriate technical and
        organizational measures, to protect against unauthorized or unlawful
        processing of personal data and against accidental loss or destruction
        of, or damage to, personal data, appropriate to the harm that might
        result from the unauthorized or unlawful processing or accidental loss,
        destruction or damage and the nature of the data to be protected. You
        can access a copy of our{" "}
        <a
          href='https://tradedepot.co/privacy'
          target='_blank'
          rel='noreferrer'
        >
          privacy policy
        </a>
        .
      </p>
      <h3>
        12. CLOSING, RESTRICTION & CANCELLATION OF YOUR TRADEDEPOT Wholesale
        PROFILE
      </h3>
      <p>
        12.1. TradeDepot may at its sole discretion restrict or close Your
        TradeDepot Wholesale Profile if:
        <br />
        <br />
        12.1.1. You breach any of the Authorized User Restrictions set out in
        this Terms of Use.
        <br />
        <br />
        12.1.2. You use Your TradeDepot Wholesale Profile for unauthorized
        purposes or where TradeDepot detects any abuse/misuse, breach of
        content, fraud or attempted fraud relating to Your use of the Services.
        <br />
        <br />
        12.1.3. TradeDepot is required to comply with an order or instruction of
        or a recommendation from the government, court, regulator or other
        competent authority.
        <br />
        <br />
        12.1.4. TradeDepot reasonably suspects or believes that You are in
        breach of this Terms of Use and the Related Agreements.
        <br />
        <br />
        12.1.5. Closing or restriction is necessary as a consequence of
        technical problems or for reasons of safety; to facilitate update or
        upgrade the contents or functionality of the Services from time to time;
        or where Your TradeDepot Wholesale Profile/Wallet becomes inactive or
        dormant.
        <br />
        <br />
        12.1.6. TradeDepot decides to suspend or cease the provision of the
        Services for commercial reasons or for any other reason as it may
        determine in its absolute discretion.
        <br />
        <br />
        12.1.7. You breach any of the provisions specified in this Terms of Use
        and the Related Agreements.
        <br />
        <br />
        12.2. Closing or restriction of Your TradeDepot Wholesale Profile shall
        however not affect any of Our accrued rights and Your liabilities to Us.
        <br />
        <br />
        12.3. You may cancel Your TradeDepot Wholesale Profile and terminate the
        Terms of Use at any time by contacting Us via the App, or via{" "}
        <a href='tel:+2348005550555'>08005550555</a> , via WhatsApp on{" "}
        <a href='https://wa.me/+2348175551555' target='_blank' rel='noreferrer'>
          +234 817 555 1555
        </a>{" "}
        or you can send us an e-mail via{" "}
        <a href='mailto:enquiries@TradeDepot.co'>enquiries@TradeDepot.co</a> .
        <br />
        <br />
        12.4. Upon cancellation of Your TradeDepot Wholesale Profile, TradeDepot
        will cease providing You with the Services and You shall no longer be
        able to access Your TradeDepot Wholesale Profile/Wallet.
      </p>
      <h3>13. DISCLAIMER OF WARRANTIES & LIMITATION OF LIABILITY</h3>
      <p>
        13.1. TradeDepot shall not be responsible for any loss suffered by You
        should Your TradeDepot Wholesale Profile be closed, restricted, or
        cancelled.
        <br />
        <br />
        13.2. TradeDepot shall not be responsible for any loss suffered by You
        should the Services be interfered with or be unavailable by reason of
        the failure of any of Your device, lack of internet connection or any
        other circumstances whatsoever not within Our control including, without
        limitation, system error, interruption, delay or non- availability of
        the Services, loss of power, adverse weather or atmospheric conditions,
        and failure of any public or private telecommunications system.
        <br />
        <br />
        13.3. You hereby agree that, to the extent permitted by applicable laws,
        TradeDepot shall not be liable for any direct, indirect, incidental,
        special, consequential or exemplary damages, including but not limited
        to, damages for loss of profits, goodwill, use, data or other intangible
        losses resulting from the use of or inability to use the Service.
        <br />
        <br />
        13.4. TradeDepot shall have no liability to You for any loss of profit,
        loss of business, business interruption, or loss of business opportunity
        as a result of Your unauthorized use of the App.
        <br />
        <br />
        13.5. TradeDepot shall not be liable for any losses or damage suffered
        by You as a result of or in connection with:
        <br />
        <br />
        13.5.1. any effect or fault in the App or the Services resulting from
        You having altered or modified the App;
        <br />
        <br />
        13.5.2. any defect or fault in the App resulting from You having used
        the App in breach of this Terms of Use and the Related Agreements; and
        <br />
        <br />
        13.5.3. Your breach of any of the provisions of this Terms of Use and
        the Related Agreements.
        <br />
        <br />
        13.6. TradeDepot does not warrant that the Services will be
        uninterrupted, timely, and secure or error free. All warranties and
        obligations implied by law are hereby excluded to the fullest extent
        permitted by law.
        <br />
        <br />
        13.7. If the Services are interfered with or unavailable, Our sole
        liability under this Terms of Use respect thereof shall be to
        re-establish the Services as soon as reasonably possible.
        <br />
        <br />
        13.8. For the avoidance of any doubt, the services and all information,
        content, materials, products (including the app) and other services
        included on or otherwise made available to you through the services are
        provided by TradeDepot on an "as is" and "as available" basis, unless
        otherwise specified in writing. TradeDepot makes no representations or
        warranties of any kind, express or implied, as to the operation of the
        services, or the information, content, materials, products (including
        the app) or other services included on or otherwise made available to
        you through the services, unless otherwise specified in writing. you
        expressly agree that your use of the services is at your sole risk.
        <br />
        <br />
        To the full extent permissible by law, TradeDepot disclaims all
        warranties, express or implied, including, but not limited to, implied
        warranties of merchantability and fitness for a particular purpose.
        TradeDepot does not warrant that the services, information, content,
        materials, any products, pos terminals (including the app) or other
        services included on or otherwise made available to you through the
        services, TradeDepot’s servers or electronic communications sent from
        TradeDepot are free of viruses or other harmful components. To the full
        extent permissible by law, TradeDepot will not be liable for any damages
        of any kind arising from the use of any service, or from any
        information, content, materials, products (including the app) or other
        services included on or otherwise made available to you through any
        TradeDepot service, including, but not limited to direct, indirect,
        incidental, punitive, and consequential damages, unless otherwise
        specified in writing. In no event shall the aggregate liability of
        TradeDepot or any of its related entities exceed the greater of one
        hundred u.s. dollars (u.s. $100.00) or the amount you paid us, if any,
        in the past six months for the services giving rise to the claim. The
        limitations of this subsection shall apply to any theory of liability,
        whether based on warranty, contract, statute, tort (including
        negligence) or otherwise, and whether or not TradeDepot or any of its
        related entities have been informed of the possibility of any such
        damage, and even if a remedy set forth herein is found to have failed of
        its essential purpose.
      </p>
      <h3>14. INDEMNITY</h3>
      <p>
        14.1. You hereby agree to indemnify and hold harmless TradeDepot (and
        its directors, officers, affiliates, agents, successors and assigns
        against all claims, liabilities damages, costs and expenses ( including
        attorney fees and related charges)) which TradeDepot suffers or incurs
        or sustains thereby, and You absolve TradeDepot from all liability for
        loss or damage which You may sustain from TradeDepot acting on Your
        instructions and/or instructions coming from Your TradeDepot Wholesale
        Profile, or sent electronically by; telephone, e-mail and letters in
        accordance with this Terms of Use and the Related Agreements.
        <br />
        <br />
        14.2. The indemnity in clause 14.1 above, shall also include the
        following:
        <br />
        <br />
        14.2.1. All demands, claims, actions, losses and damages of whatever
        nature which may be brought against TradeDepot or which We may suffer or
        incur arising from its acting or not acting on any request from Your
        Profile, or sent electronically by; telephone, e-mail and letters, or
        arising from the malfunction or failure or unavailability of any
        hardware, software, or equipment, the loss or destruction of any data,
        power failures, corruption of storage media, riots, acts of vandalism,
        sabotage, terrorism, any other event beyond Our control.
        <br />
        <br />
        14.2.2. Any loss or damage that may arise from Your use, misuse, abuse
        or possession of any third-party software, including without limitation,
        any operating system, browser software or any other software packages or
        programs.
        <br />
        <br />
        14.2.3. Any unauthorized access to Your TradeDepot Wholesale Profile or
        any breach of security or any destruction or accessing of Your data or
        any destruction or theft of or damage to any of Your device.
        <br />
        <br />
        14.2.4. Any loss or damage occasioned by Your failure to adhere to this
        Terms of Use and/or by supplying incorrect information.
        <br />
        <br />
        14.2.5. Any loss or expenses arising from a fraud perpetrated by You or
        any expenses incurred by us in engaging the relevant regulatory
        authorities as may become necessary on account of your actions.
      </p>
      <br /> <br />
      <h3>CASH ADVANCE TERMS AND CONDITIONS</h3>
      <p>
        If You wish to obtain a Cash Advance from TradeDepot, bear in mind, the
        provisions of this Cash Advance Terms and Conditions ( also referred to
        as the “<b>Agreement</b>”) are binding on You in addition to the Terms
        of Use. The purpose of this Agreement is to outline the terms upon
        which, We may grant you a Cash Advance for the purpose of purchasing
        stock of Consumer Goods (the “<b>Products</b>”) for your business.{" "}
        <b>
          Our overarching objective of granting You Cash Advance is to help you
          to grow your business, help You to serve Your customers better and
          help you to succeed
        </b>
        . We fully expect that You would reciprocate this gesture by paying all
        amounts due and outstanding on the Cash Advance granted to You as and
        when due. Please be aware that We can only grant You a Cash Advance
        through the App.
        <br />
        <br />
        This Agreement, (together with our Privacy Policy{" "}
        <a
          href='https://tradedepot.co/privacy'
          target='_blank'
          rel='noreferrer'
        >
          Tradedepot Wholesale Privacy
        </a>{" "}
        and the Terms of Use, referred to as the “<b>Related Agreements</b>”)
        sets out the complete terms and conditions upon which We may grant you a
        Cash Advance. This Agreement must therefore be read in conjunction with
        the Related Agreements. Be aware that this Agreement and the Related
        Agreements take effect on their date of publication. This Agreement and
        the Related Agreements may be amended or varied by TradeDepot from time
        to time and Your continued use and access to the Cash Advance
        constitutes Your agreement to be bound by the terms of any such
        amendment or variation.
        <br />
        <br />
        TradeDepot will take all reasonable measures to notify You of any
        changes. By requesting for a Cash Advance, You irrevocably and
        unconditionally agree that this Cash Advance Terms and Condition is
        legally a binding contract between TradeDepot as the “<b>Lender</b>” and
        yourself as the “<b>Borrower</b>”. You authorize us to take all legal
        steps necessary and to share Your Personal Data with other third
        parties, including credit bureaus and government agencies, with the
        objective of recovering all amounts due and unpaid on any Cash Advance
        granted to You.
        <br />
        <br />
        It is Your sole responsibility to procure the services of a lawyer or
        other professional where You do not understand any of the terms of this
        Agreement to explain or advise You as necessary. By accepting a Cash
        Advance, You agree that You
        <br />
        (a) have read and fully understand the terms of this Agreement;
        <br />
        (b) all the details above and the information supplied are accurate and
        true, and that You are aware that, we may report You to the Nigerian
        Police Force (<b>NPF</b>) or to the Economic and Financial Crimes
        Commission, (<b>EFCC</b>), where any of your actions or omissions in
        relation to this Agreement raises any suspicion of any criminal intent,
        action or omission;
        <br />
        (c) You are aware that You are liable to pay interest and other
        transaction charges in connection with a Cash Advance received from Us;
        <br />
        (d) Any questions You had in relation to the Agreement were answered to
        Your satisfaction.
        <br />
        (e) If we have to deploy resources to follow up on overdue payments, you
        will bear all expenses related to this exercise
      </p>
      <h3>1. ELIGIBILITY FOR CASH ADVANCE</h3>
      <p>
        1.1. To be eligible for Cash Advance, You must:
        <br />
        (a) have an existing business;
        <br />
        (b) signup and register on the TradeDepot Wholesale App or be an
        existing user;
        <br />
        (c) complete our full KYC (Know Your Customer) processes and have Your
        physical addresses verified
        <br />
        (d) keep the account/wallet funded;
        <br />
        (e) make a purchase of Goods consistent with the Minimum Order
        Requirement;
        <br />
        (f) must have completed at least three purchases on the App
        <br />
        (g) Apply for the Cash Advance;
        <br />
        (h) agree to all terms and conditions as well as all fee, charges and
        expenses payable in respect thereof. By applying for a Cash Advance, You
        hereby consent to and give us access to Your bank accounts for the
        purpose of assessing Your creditworthiness for a Cash Advance.
        <br />
        <br />
        1.2. Customers requesting for Cash Advance above ₦500,000 (Five hundred
        thousand Naira) (“<b>Cash Advance Plus Customer</b>”.) will in addition
        to the above requirements provide
        <br />
        (a) a statement of account covering the last 6 (Six) months prior to the
        date of Your application for a Cash Advance
        <br />
        (b) All company registration documentation showing that a director is
        the one applying for the Cash Advance
        <br />
        <br />
        1.3. By accepting a Cash Advance from Us, You hereby agree that you have
        fulfilled the above eligibility requirements which relates to You. You
        agree that if we find out later that You do not meet up with the
        eligibility requirements, we reserve the rights to immediately terminate
        this Agreement and immediately request, using all legal means possible,
        for a refund to Us, of the Cash Advance granted to You, including
        interest on the Cash Advance ( “Cash Advance Interest”)
        <br />
        <br />
        1.4. You hereby authorize Us to act on every request for a Cash Advance
        which You submit electronically through the App and agree that any
        application for a Cash Advance or acceptance of an offer of a Cash
        Advance submitted or generated electronically through the App shall have
        the same force and effect as if that application or acceptance bears
        inked original signature(s).
        <br />
        <br />
        1.5. You hereby represent and warrant that all the information and
        documents provided by You are true, correct, and complete. In the event
        that the information provided by You changes, You agree to immediately
        notify Us of such changes. If We find out that any information You
        provide is incorrect and false, whether as a result of Your negligence
        or fraudulent intent, We would terminate this Agreement and immediately
        request, using all legal means possible, for a refund to Us, of the Cash
        Advance granted to You, including any Cash Advance Interest.
        <br />
        <br />
        1.6. We reserve the right to verify the authenticity and status of Your
        national identity number (NIN), bank accounts and bank verification
        numbers with the relevant service providers which may include a mobile
        network operator, credit bureau, deposit banks, and other financial
        institutions. By applying for a Cash Advance, You hereby consent and
        authorize Us to conduct background checks on You and to procure a credit
        report or other information on/about You from a credit bureau or any of
        such service providers. You agree to pay to Us, any costs and expenses
        associated with such background, credit reports/ checks or
        investigations, which is non refundable, whether or not your cash
        advance application is successful.
        <br />
        <br />
        1.7. TradeDepot reserves the right to decline Your application for a
        Cash Advance or to revoke that request/application at any stage at
        TradeDepot’s sole and absolute discretion and without giving any reason
        or giving any notice thereto. TradeDepot reserves the right (in its sole
        and absolute discretion) to issue, decline to extend a Cash Advance
        and/or vary the terms of any Cash Advance depending on Our assessment of
        Your eligibility from time to time.
      </p>
      <h3>2. ACCEPTANCE OF CASH ADVANCE OFFER</h3>
      <p>
        2.1. You hereby accept Our offer of Cash Advance granted to You through
        the App, subject to provisions of this Cash Advance Terms of Use and the
        Related Agreements. The monetary value of the Cash Advance granted to
        You may be indicated in your TradeDepot Wholesale Wallet. Some of the
        terms of the Cash Advance granted to You can be displayed through your
        dashboard on the App. These terms may include:
        <br />
        (a) the principal amount of the Cash Advance;
        <br />
        (b) the applicable Cash Advance Interest; and
        <br />
        (c) the period within which You have to repay the Cash Advance and the
        Cash Advance Interest.
      </p>
      <h3>3. PURPOSE OF CASH ADVANCE</h3>
      <p>
        3.1. You must only use the Cash Advance hereby granted for purchasing
        Products from the App or from a registered Pay Partner. You cannot use
        the Cash Advance for any other purpose or for any personal, family or
        household purposes. You are required to meet all covenants relating to
        the cash advance, including the volume of transactions requirement which
        is 5x the daily repayment obligation. This is to give the assurance that
        the repayment is not a strain on your business
      </p>
      <h3>4. YOUR TRADEDEPOT WHOLESALE WALLET</h3>
      <p>
        4.1. The monetary value of the Cash Advance granted to You may be
        indicated in Your TradeDepot Wholesale Wallet through the unique account
        number associated with Your online account on the App (“
        <b>Unique Account Number</b>”) and assigned to You at the point of
        registration on the App as a TradeDepot Retailer.
      </p>
      <h3>5. YOUR USE AND PURCHASE OF A POS TERMINAL</h3>
      <p>
        5.1. If You are a Cash Advance Plus Customer, We may provide You with a
        Point of Sale Terminal (the “<b>PoS TerminalM</b>”) which You have to
        pay for. You will pay the amounts chargeable on the PoS Terminal upfront
        but we may, in our sole discretion, spread all or some of the said
        payment over the tenor of the Cash Advance. You may also be able to link
        your own POS terminal if the partnership exists
        <br />
        <br />
        5.2. In consideration of the Cash Advance granted to You, You hereby
        unconditionally and irrevocably appoint us as a cash collection agent,
        during the tenor of a Cash Advance or within any period of time when a
        Cash Advance to You is due and outstanding.
        <br />
        <br />
        5.3. Pursuant to our appointment as Your cash collection agent, You
        hereby authorize Us to
        <br />
        (a) use our App to collect the electronic payments made by Your
        customers to You through the PoS Terminal ( the “Electronic Payments”);
        <br />
        (b) use our App to deduct, from the Electronic Payments, on a daily
        basis, any amounts due and unpaid to Us on account of any Cash Advance
        provided to You, including a Cash Advance Interest
        <br />
        (c) after deducting the amounts due to Us from the Electronic Payments,
        transfer the balance on the Electronic Payments to Your linked bank
        account
        <br />
        (d) deduct any amounts due and outstanding to Us from Your bank account
        where the Electronic Payments are insufficient to repay the Cash Advance
        or any amounts due to Us.
        <br />
        <br />
        5.4. As further consideration for our provision of a Cash Advance to
        You, You hereby agree unconditionally and irrevocably to use either the
        PoS Terminal or transfers to your POS account with TradeDepot Wholesale
        for accepting all payments from customers during the Tenor or within any
        period of time when a Cash Advance to You is due and outstanding. You
        agree not to use another PoS terminal for processing the Electronic
        Payments during the tenor of a Cash Advance. You acknowledge that the
        PoS Terminal is simply a channel we have provided to make it easy for
        You to repay a Cash Advance, a Cash Advance Interest and any amounts due
        thereon. Therefore, You will continue to remain liable to repay the Cash
        Advance and any amounts due thereon in the event We are unable to
        recover any amounts due to Us and it is Your responsibility to ensure
        that any Cash Advance granted to You is repaid as and when due, whether
        through Your TradeDepot Wholesale Wallet, the PoS Terminal, transfer to
        your POS account, or through other approved methods of payment approved
        in this Agreement or any related Agreement.
        <br />
        <br />
        5.5. You agree to handle the PoS Terminal received from us with utmost
        care and to comply with any instruction manuals and training provided to
        You in connection with Your Use and operation of the POS Terminal
        <br />
        <br />
        5.6. We may initially provide You with free printing paper for the
        purpose of operating the PoS Terminal received from us. It would be Your
        responsibility, to replace the said printing paper when it finishes and
        from time to time. We may also provide You with free internet data
        subscription for the PoS Terminal for the first 18 (Eighteen) months
        counting from the time a PoS Terminal is provided to You. After the said
        first 18 (Eighteen) months, it will be Your responsibility to replace
        and/or purchase the data subscription that is necessary to operate the
        PoS Terminal.
        <br />
        <br />
        5.7. As a Cash Advance Plus Customer, You will only own the PoS Terminal
        after You have fully paid for the outstanding amounts relating to the
        cost of the PoS Terminal in accordance with clause 5.1. herein
        <br />
        <br />
        5.8. You hereby acknowledge that the PoS Terminal provided to You is new
        and is in good working condition.
        <br />
        <br />
        5.9. You agree that TradeDepot shall have the sole right to repair or
        replace any PoS Terminal. You agree that You shall not attempt to repair
        any such malfunctioning, damaged or destroyed PoS Terminal, whether by
        Yourself or through any other vendor.
        <br />
        <br />
        5.10. You agree to notify TradeDepot within 1 (One) hour from the time
        any PoS Terminal malfunctions or is damaged or destroyed. You also agree
        to use the PoS Terminal in accordance with the manuals or instruction
        for the operation of the PoS Terminal.
        <br />
        <br />
        5.11. You agree to pay the full costs for repair or replacement of any
        such damaged PoS Terminal. You must deposit the full costs of such
        repair as advised by Us into your TradeDepot Wholesale Wallet or
        directly to the manufacturer. During the period of any repair of the Pos
        Terminal you are still required to be making payment to your POS account
        to enable us make the relevant daily deductions of the amounts due and
        payable to Us.
        <br />
        <br />
        5.12. The PoS Terminal is manufactured by third parties and resold by
        TradeDepot. While We use reasonable efforts to maintain sufficient
        supply of the PoS Terminal, inventory shortages at our manufacturers and
        distributors may affect our ability to fulfil an order. If there is a
        delay in manufacture or distribution of a PoS Terminal that we believe
        will affect Your order, we will use reasonable efforts to notify You of
        the delay and keep You informed of the revised delivery schedule. We are
        not obliged to accept any order, and we may cancel accepted orders at
        any time if we are unable to fulfil the order for any reason. If you
        submit an order, and we accept the order, you are bound by the order
        unless We cancel the order. If we cancel your order, we will fully
        refund the purchase price to You.
        <br />
        <br />
        5.13. Prices for the PoS Terminal will be listed at the time of Your
        purchase and may vary by country. We may change pricing and availability
        at any time. If you do not pay us the amounts payable for a PoS
        Terminal, we may delay shipment of the PoS Terminal until the relevant
        payment is received by Us. The amounts you pay for the PoS Terminal is
        in addition to the fees you pay for other business services that we may
        offer You.
        <br />
        <br />
        5.14. Unless otherwise stated, the prices shown for the PoS Terminal
        exclude taxes and shipping costs. We may add all taxes and shipping
        costs to the total amount of your purchase, and show you the final
        amount due before you complete your purchase. You are solely responsible
        for all taxes and shipping costs that apply to the PoS Terminal you
        purchase.
        <br />
        <br />
        5.15. We may collect amounts you owe us for your purchase of a PoS
        Terminal by deducting or setting-off those amounts from the balance on
        your Shop Top Up wallet. If that balance is insufficient, we may take
        steps to repossess the PoS Terminal
        <br />
        <br />
        5.16. You are responsible for and will pay, indemnify, and hold
        TradeDepot harmless from any sales, use, excise, import or export,
        withholding, value added, or similar tax (“<b>Taxes</b>”), and any
        penalties or interest associated with Taxes, imposed by any governmental
        authority with Your purchase and use of the PoS Terminal. We may charge,
        and You must pay, all Taxes that We are legally obligated or allowed to
        collect based on your purchase of the PoS Terminal. All fees payable for
        the PoS Terminal are exclusive of Taxes except to the extent We
        expressly state to the contrary. You must provide Us with any
        information we need to determine whether we are required to collect
        Taxes from You under any applicable Law.
        <br />
        <br />
        5.17. If You already use another PoS terminal to process payments as of
        the date of Your entry into this Agreement, (“
        <b>3rd Party PoS Terminal</b>”) we may allow use such 3rd Party PoS
        Terminal to process the Electronic Payments subject to conditions we may
        specify from time to time. In any event, before Our approval of Your use
        of a 3rd Party PoS Terminal, you must link such 3rd Party PoS Terminal
        to Your TradeDepot Wholesale Wallet.
        <br />
        <br />
        5.18. You agree that any breach of this clause will amount to an Event
        of Default under this Agreement and that, in that event, We shall have
        the right to immediately trigger our enforcement and repossession rights
        in relations to the Products pursuant to the terms of this Agreement.
        <br />
        <br />
      </p>
      <h3>6. TENOR, CASH ADVANCE INTEREST, TRANSACTION AND OTHER FEES</h3>
      <p>
        6.1. You would be required to pay Us a Cash Advance Interest which would
        be advised at the time of Your application for a Cash Advance. The Cash
        Advance Interest may be charged on a reducing balance basis.
        <br />
        <br />
        6.2. As a Cash Advance Plus Customer, You will also be required to pay
        all costs associated with Our delivery of the PoS Terminal to You,
        should we choose to deliver a PoS Terminal to You.
        <br />
        <br />
        6.3. We and/or Our payment partners may charge transaction fees, in
        connection with the transactions carried on within your TradeDepot
        Wholesale Wallet and from time to time amend or vary the transaction
        fees. If You are a Cash Plus Advance Customer, transactions on Your PoS
        Terminal shall be subject to a transaction charge for every transaction
        on the PoS Terminal. The relevant transaction charge shall be notified
        to You at the time of Your application for a Cash Advance. We will use
        reasonable endeavors to notify You of any changes in relation to
        transaction charges.
        <br />
        <br />
        6.4. You shall be responsible to pay the costs associated with
        registration of our security interests over the Products at the relevant
        movable asset registry.
        <br />
        <br />
        6.5. If You do not repay the Cash Advance, as and when due, You shall be
        liable to pay Us a late payment fee of up to 48% (Forty-Eight Percent)
        of the total amount due and outstanding on the Cash Advance together
        with any outstanding Cash Advance Interest (“<b>Late Payment Fees</b>”).
        <br />
        <br />
        6.6. You shall be responsible for all legal costs and expenses incurred
        by Us in connection with any enforcement action taken by Us towards the
        collection and repayment of the Cash Advance and all amounts due and
        outstanding on any Cash Advance granted to You, including the costs of
        repossession of any Products and the legal expenses incurred towards
        filing a legal action against You for the recovery of any amounts due to
        Us.
      </p>
      <h3>7. SECURITY</h3>
      <p>
        7.1. As security for our advance of the Cash Advance to You, You hereby
        as Chargor, grants to Us, as Chargee, by way of a hypothecation, for Our
        benefit and Our lenders or assigns, a first priority security interest
        in:
        <br />
        (i) all the Products which constitutes the inventory/stock of Your
        business hereto now owned or hereafter acquired by You or Your business
        (collectively, the “<b>Charged Interests</b>”),
        <br />
        (ii) all other property hereafter delivered to, or in the possession or
        in Chargor’s custody, in substitution for or in addition to the Charged
        Interests,
        <br />
        (iii) any other property of Chargor, now or hereafter delivered to, or
        in the possession or custody of Chargor, and
        <br />
        (iv) all proceeds from the sale of the Products, as collateral security
        for:
        <br />
        (a) the prompt and complete payment when due (whether at the stated
        maturity, by acceleration or otherwise) of all amounts outstanding and
        unpaid relating to the Cash Advance, the Cash Advance Interest; and
        <br />
        (b) the due and punctual payment and performance by You of Your
        obligations and liabilities under, arising out of or in connection with
        this Agreement. You agree that the security hereby created gives
        TradeDepot the right to a repossession of the Products or any other
        products in Your store/warehouse, including any store/warehouse
        controlled by You, at the time of any repossession by Us where you fail
        to comply with the terms of this Agreement or when any amount is due and
        unpaid by You in connection with a Cash Advance.
        <br /> <br />
        7.2. As additional security for the Cash Advance hereby granted, You
        hereby convey, assign, transfer the contractual and property rights to
        repossess without a Court Order, any and all Products in Your custody at
        the time of default, (“<b>Repossessed Property</b>”) upon Your failure
        to repay the Cash Advance or any amount due and outstanding on the Cash
        Advance or upon the occurrence of an Event of Default. You waive any
        rights to sue Us or any of our recovery agents for trespass, breach of
        privacy, breach of data privacy or breach of peace or any other legal
        rights You may have under any law, in so far as the actions taken by Us
        or Our recovery agents are directed towards recovery of all amounts due
        and outstanding on the Cash Advance granted to You. You hereby waive the
        rights to any notice or judicial or court hearing relating to Our
        exercise of the rights to repossession herein granted. You agree that we
        may sell, lease or otherwise dispose of the Repossessed Property and
        that We may apply what We receive from the disposition of the
        Repossessed Property to Our expenses, legal expenses and the outstanding
        amounts on the Cash Advance granted to You. You agree that if the
        Repossessed Products are perishables or threatens to decline speedily in
        value, We may, without notice to You, dispose of any or all such
        Repossessed Property in a commercially reasonable manner and at Your
        expense.
        <br />
        <br />
        7.3. You shall deposit with TradeDepot post dated cheques for the amount
        of installments or the complete amount as agreed. These cheques shall be
        presented to the bank by TradeDepot when there is an overdue payment and
        in TradeDepot’s opinion, efforts attempted to receive payments have
        failed.
        <br /> <br />
        The cheques will be from the corporate account of the business. Under
        special circumstances, personal cheques or cheques from guarantors may
        be accepted
        <br /> <br />
        You hereby authorize Trade Depot to present the cheques for payment if
        required. You confirm that these cheques have not been provided under
        duress but as an obligation to be fulfilled to safeguard against default
      </p>
      <h3>8. OUR RIGHT AND NOTICE TO ASSIGN TO THIRD PARTIES</h3>
      <p>
        8.1. Trade Depot reserves the rights to assign the amount of any Cash
        Advance herein granted to You (including the amount of any Cash Advance
        Interest and any related security, rights, obligations, interests or
        receivables) whereof TradeDepot hereby notifies You of the transfer and
        assignment of all its rights, title to the Cash Advance hereby granted
        to You, (including the amount of any Cash Advance Interest and any
        related security, rights, obligations or interests), to TD Capital SPC
        acting for the account and on behalf of Segregated Portfolio, SP1 (the “
        <b>Buyer</b>”) (“<b>Notice of Assignment</b>”). By accepting Our offer
        of Cash Advance, You hereby acknowledge receipt of this Notice of
        Assignment and affirm that You have not received notice that any other
        person has an interest in the Cash Advance herein granted to You.
        Notwithstanding the foregoing, You will continue to be liable to pay all
        amounts due and outstanding, including any Cash Advance Interest in
        accordance with the provisions Agreement or as required by TradeDepot
        from time to time. You consent irrevocably and unconditionally to our
        provision to the Buyer of all information relating to the Cash Advance
        hereby granted to You including all your personal and business
        information provided at the time of registration on the App and at the
        time that You applied for a Cash Advance, as may from time to time be
        requested by the Buyer or its agents.
      </p>
      <h3>9. REPAYMENT OF CASH ADVANCE, CASH ADVANCE INTEREST AND LATE FEES</h3>
      <p>
        9.1. All repayments of the Cash Advance and the Cash Advance Interest
        are to be made through the App or/and as provided for in clause
        9.1(i)-(iv) or in the case of a Cash Advance Plus Customer, through the
        PoS Terminal, the POS account or as agreed with You. You may repay all
        amounts due and outstanding on the Cash Advance hereby granted to You
        by:
        <br />
        <br />
        9.1.1. Paying all amounts due and outstanding on the Cash Advance
        including the Cash Advance Interest and any Late Fees, through the
        Payment Service Provider platform integrated within the App.
        <br />
        <br />
        9.1.2. Funding Your TradeDepot Wholesale Wallet with all amounts due and
        outstanding on the Cash Advance including the Cash Advance Interest and
        any Late Fees, through electronic transfer to Your Unique Account Number
        to enable direct debits to be made in favor of TradeDepot towards Your
        repayment of the Cash Advance extended to You by TradeDepot.
        <br />
        <br />
        9.1.3. Funding Your TradeDepot Wholesale Wallet with all amounts due and
        outstanding on the Cash Advance including the Cash Advance Interest and
        any Late Fees, by using the Unstructured Supplementary Service Data
        (USSD) code displayed on Your online profile on the App, to enable
        direct debits to be made in favor of TradeDepot towards Your repayment
        of the Cash Advance extended to You by TradeDepot.
        <br />
        <br />
        9.1.4. Visiting the nearest mobile money agent or bank to deposit money
        into Your Unique Account Number, to enable direct debits to be made from
        Your TradeDepot Wholesale Wallet in favor of TradeDepot for the
        repayment of the Cash Advance extended to You by TradeDepot.
        <br />
        <br />
        9.2. You hereby agree and give Your consent to the sharing of Your
        Information with third parties to the extent required to ensure
        repayment of the Cash Advance You obtained from Us.
        <br />
        <br />
        9.3. By obtaining a Cash Advance from TradeDepot, You hereby authorize
        that daily automatic debit deductions be made in favor of TradeDepot
        from Your TradeDepot Wholesale Wallet or the PoS Terminal, towards the
        repayment of the Cash Advance, Cash Advance Interest, Late Fees,
        applicable taxes, standard bank charges, and any fees incurred in
        relation to the grant of the Cash Advance.
        <br />
        <br />
        9.4.All payments to be made by You under this Agreement shall be made in
        full without any set off or counterclaim and save in so far as required
        by the law to the contrary, free and clear of and without any deduction
        or withholding whatsoever. If You are at any time required to make any
        deduction or withholding from any payment to TradeDepot, You shall
        immediately pay to TradeDepot such additional amounts as will result in
        TradeDepot receiving the full amount it would have received had no such
        deduction or withholding been required.
      </p>
      <h3>10. EVENTS OF DEFAULT</h3>
      <p>
        10.1. An event of default (<b>Event of Default</b>) occurs when:
        <br />
        <br />
        10.1.1. You fail to pay any sum payable pursuant to the Cash Advance
        granted to You in accordance with a scheduled repayment, for any reason
        at all.
        <br />
        <br />
        10.1.2. You fail to pay the Cash Advance Interest in accordance with a
        scheduled repayment.
        <br />
        <br />
        10.1.3. Any representation/information, warranty or assurance made or
        given by you in connection with the application for Cash Advance or any
        information or documentation supplied by you is incorrect, inaccurate or
        misleading.
        <br />
        <br />
        10.1.4. You do anything which may prejudice TradeDepot’s rights under
        this Agreement.
        <br />
        <br />
        10.1.5. You use or attempt to use the App for any unauthorized purpose.
        <br />
        <br />
        10.1.6. You are declared bankrupt, insolvent or unable to pay Your debts
        as they fall due under the laws of Nigeria.
        <br />
        <br />
        10.1.7. As a Cash Advance Plus Customer, You fail for any reason at all
        to meet the usage covenant that shows the volume of transactions, either
        through the use of the PoS Terminal or transfers to the POS account on
        any day during the Term or during which any amount due and payable by
        You in connection with a Cash Advance is outstanding and unsettled.
        <br />
        <br />
        10.1.8. You fail to
        <br />
        (a) fully comply with any trainings provided to You regarding the use of
        the POS Terminal or Your Shop Top-Up Wallet
        <br />
        (b) fully comply with any instruction manuals provided to You in
        connection with the operation of the POS Terminal
        <br />
        (c) immediately notify TradeDepot in the event of a damage, destruction
        or malfunction of a PoS Terminal.
        <br />
        <br />
        10.1.9. Where the POS Terminal is destroyed or damaged during the time
        when it is within Your possession or use.
        <br />
        <br />
        10.1.10. Where You fail to pay for the replacement or repair a POS
        Terminal which malfunctions, is destroyed or damaged during the time
        when it is within Your possession or use.
        <br />
        <br />
        10.1.11. Where we are unable to make the relevant daily deductions of
        the amounts due to Us through funds in your POS account, through a PoS
        Terminal or transfer or where Our ability to collect the Electronic
        Payments or any amounts due to Us is hampered in any way.
        <br />
        <br />
        10.1.12. You fail to inform TradeDepot of any outstanding debt/loan You
        owe to Your 3rd Party PoS Terminal provider. 10.2. At any time after an
        Event of Default has occurred which is continuing, TradeDepot may,
        without prejudice to any other right or remedy granted to it under any
        law:
        <br />
        <br />
        10.2. At any time after an Event of Default has occurred which is
        continuing, TradeDepot may, without prejudice to any other right or
        remedy granted to it under any law:
        <br />
        <br />
        10.2.1. terminate this Agreement immediately;
        <br />
        <br />
        10.2.2. declare that the Cash Advance (and all accrued Cash Advance
        Interest and all other amounts outstanding under this Agreement is
        immediately due and payable, whereupon they shall become immediately due
        and payable; and
        <br />
        <br />
        10.2.3. supply information concerning the Event of Default to credit
        bureaus licensed to hold such Cash Advance default information in
        Nigeria. A copy of any adverse information concerning You sent to a
        credit bureau shall be made available to You upon written request.
        <br />
        <br />
        10.3. TradeDepot, whether acting for itself or for any of its assigns,
        reserves the right to assign its right, title and interest under the
        Agreement, including the right to sue for any unpaid amounts due and
        outstanding on the Cash Advance, to an external collections agency or to
        any third party who will take all reasonable steps to collect the
        amounts outstanding on any Cash Advance (together with any Cash Advance
        Interest, Late Fees and fees accrued), and in this regard, TradeDepot
        shall have the right to share with such collection agencies, any
        information about the Borrower which it deems relevant. Third parties
        engaged by Us towards the recollection of any outstanding amounts may
        share Your information with other entities or contact Your family
        members, friends, guarantors in a bid to recover all amounts outstanding
        and Trade Depot will not be held liable for any misuse of the data. You
        hereby consent to the processing of Your personal data in this manner
        and for this purpose.
        <br />
        <br />
        10.4. TradeDepot also reserves the right to institute legal proceedings
        for the recovery of any amounts due and outstanding against You without
        any prior notice.
        <br />
        <br />
        10.5. In the event that an Event of Default occurs, You grant to
        TradeDepot the right to notify You and any other person or third party
        related or affiliated to You, who, in Trade Depot’s opinion, may assist
        with the recovery of the outstanding amounts on the Cash Advance granted
        to You. You agree that this notification may be done by any means of
        communication which TradeDepot deems appropriate. By signing this
        Agreement, You hereby consent to the processing of Your personal data in
        this manner and waive any legal standing or defenses You may have in
        that regard.
      </p>
      <h3>11. STATEMENTS</h3>
      <p>
        11.1. A statement and activity report in respect of the Cash Advance
        granted to You will be made available on request. Requests shall be made
        via our e-mail address:{" "}
        <a href='mailto:enquiries@TradeDepot.co'>enquiries@TradeDepot.co</a> or
        via a contact link on the App.
        <br />
        <br />
        11.2. The statement on the App shall provide details of the last 4
        (four) transactions (or such other number of transactions as determined
        by TradeDepot) on Your Cash Advance account initiated from Your device.
        Your statement will show all amounts added or taken from Your Cash
        Advance account. You must check Your statement carefully and notify
        TradeDepot as soon as possible, and no later than 48 hours after receipt
        of Your statement, if it includes any transaction or other entry which
        appears to You to be wrong or not made in accordance with Your
        instructions.
        <br />
        <br />
        11.3. TradeDepot reserves the right to rectify discrepancies, add and/or
        alter the entries in Your statements, without prior notice to You.
        <br />
        <br />
        11.4. Save for a manifest error, a statement issued to You in respect of
        Your Cash Advance shall be conclusive evidence of the transactions
        carried out on Your Cash Advance account for the period covered in the
        statement.
      </p>
      <h3>12. Taxes</h3>
      <p>
        12.1. All payments to be made by You in connection with this Agreement
        or any Cash Advance provided to You by TradeDepot are calculated without
        regard to any taxes payable by You in Nigeria. If any taxes are payable
        in connection with the repayments of the Cash Advance granted to You,
        You must ensure that You pay such taxes and that You pay to TradeDepot
        such additional amounts as will result in TradeDepot receiving the full
        amount it would have received had no such taxes been applicable to the
        payments.
        <br />
        <br />
        12.2. You hereby consent and agree that TradeDepot may withhold amounts
        in Your TradeDepot Wholesale Wallet if any tax authority in Nigeria
        requires TradeDepot to do so, or TradeDepot is otherwise required by law
        or pursuant to agreements with any tax authority to do so, or if
        TradeDepot needs to comply with internal policies or with any applicable
        order or sanction of a tax authority in Nigeria. You will, however, be
        notified if such deductions are made.
        <br />
        <br />
        12.3. You acknowledge that to the full extent permitted by law,
        TradeDepot shall not be liable for any unauthorized drawing, debit,
        transfer, remittance, disclosure, any activity or any incident on your
        TradeDepot Wholesale Wallet or online profile by the fact of the
        knowledge and/or use or manipulation of Your TradeDepot Wholesale
        profile, Your TradeDepot Wholesale Wallet, personal identification
        number (PIN), password, ID or any means whether or not occasioned by
        Your negligence or willful misconduct.
        <br />
        <br />
        12.4. You hereby agree and authorize TradeDepot to obtain and procure
        Your Personal Data from the Cash Advance bureaus and You further agree
        and consent to the disclosure and provision of such Personal Data by the
        Cash Advance bureaus.
        <br />
        <br />
        12.5. TradeDepot reserves the right to request for further information
        from You pertaining to Your application. Failure to provide such
        information within the time required by TradeDepot may result in
        TradeDepot declining to accept Your application for a Cash Advance.
        <br />
        <br />
        12.6. TradeDepot reserves the right to share information related to any
        Cash Advance shared with You and other personal information about You to
        credit bureaus. You consent and agree that TradeDepot may also seek
        information on Your credit worthiness from any such credit bureaus.
      </p>
      <h3>13. VARIATION AND TERMINATION</h3>
      <p>
        13.1. TradeDepot may at any time, upon notice to You, terminate or vary
        its business relationship with You and close Your TradeDepot Wholesale
        Wallet/Profile and in particular but without prejudice to the generality
        of the foregoing, TradeDepot may cancel a Cash Advance which it has
        granted and require the repayment of outstanding debts resulting from
        such Cash Advance within such time as TradeDepot may determine.
        <br />
        <br />
        13.2. Without prejudice to Trade Depot’s rights under clause 13.1,
        TradeDepot may at its sole discretion suspend Cash Advance to You or
        close your TradeDepot Wholesale Wallet if:
        <br />
        <br />
        13.2.1. You use the Cash Advance or Your TradeDepot Wholesale Wallet for
        unauthorized purposes or where TradeDepot detects any abuse/misuse,
        breach of content, fraud or attempted fraud relating to Your use of the
        App;
        <br />
        <br />
        13.2.2. Your agreement with a mobile network operator is terminated for
        whatever reason; or
        <br />
        <br />
        13.2.3. TradeDepot is required or requested to comply with an order or
        instruction of or a recommendation from the government, court, regulator
        or other competent authority.
        <br />
        <br />
        13.2.4. TradeDepot reasonably suspects or believes that You are in
        breach of this Agreement (including non-payment of any Cash Advance
        amount due from You where applicable) which You fail to remedy (if
        remediable) within 14 days after the service of notice by email, SMS or
        other electronic means requiring You to do so.
        <br />
        <br />
        13.2.5. Such a suspension or variation is necessary as a consequence of
        technical problems or for reasons of safety; to facilitate update or
        upgrade the contents or functionality of the App from time to time; or
        where Your TradeDepot Wholesale profile on the App or Your TradeDepot
        Wholesale Wallet becomes inactive or dormant.
        <br />
        <br />
        13.2.6. TradeDepot decides to suspend or cease the provision of the App
        or Cash Advance for commercial reasons, to protect its legitimate
        interests or for any other reason as it may determine in its absolute
        discretion.
        <br />
        <br />
        13.3. Termination shall however not affect any accrued rights of
        TradeDepot.
        <br />
        <br />
        13.4. If TradeDepot receives notice of Your demise, TradeDepot shall not
        be obliged to allow any operation or withdrawal from Your TradeDepot
        Wholesale Wallet by any person except upon production of administration
        letters from a competent authority or confirmed grant of letters of
        administration or confirmed grant of probate by Your legal
        representatives duly appointed by a court of competent jurisdiction and
        upon our recovery of any amounts due and outstanding to Us.
      </p>
      <h3>14. INDEMNITY</h3>
      <p>
        14.1. In consideration of Our grant of the Cash Advance, You agree to
        indemnify TradeDepot and it assigns, agents, employees and hold them
        harmless against any loss, charge, damage, expense, fee or claim which
        TradeDepot suffers or incurs or sustains thereby as a result of your
        breach of this Agreement, any demands, claims, actions, losses and
        damages of whatever nature which may be brought against TradeDepot by
        You or by any third party ( including regulators) or which We may suffer
        or incur arising from the grant of the Cash Advance, Your use of a PoS
        Terminal, Our enforcement of this Agreement or the response to any
        regulatory requests. You hereby absolve TradeDepot from all liability
        for loss or damage which You may sustain from TradeDepot acting in
        accordance with this Agreement.
        <br />
        <h3>GENERAL</h3>
        <h3>15. OUR COMPLAINT HANDLING PROCEDURES</h3>
        15.1. Meeting and exceeding our clients' expectations is the most
        important part of our business. However, there may be occasions when
        something goes wrong and leaves you dissatisfied. If this happens,
        please let us know and we’ll do everything We can to resolve the problem
        with You. Our aim is to always resolve such matters as soon as We can
        and to Your satisfaction.
        <br />
        <br />
        15.2. If You believe something has gone wrong and You would like to make
        a formal complaint, please get in touch and let Us know by providing as
        much detail about the issue as You can. This can be done by either{" "}
        <br />
        (a) Sending us an email at:{" "}
        <a href='mailto:enquiries@TradeDepot.co'>enquiries@TradeDepot.co</a>.
        <br />
        (b) Calling our customer care number: 08005550555
        <br />
        (c) Writing to us at: ¾ Adewunmi Industrial Estate, Kudirat Abiola Way,
        Oregun Lagos (d) Reaching us on our whatsapp line on 08175551555
        <br />
        <br />
        15.3. Once We have received Your complaint, We will begin our
        investigation into what has happened. We will always aim to resolve the
        issue as soon as possible. Once Our investigation into an issue is
        complete, will We send You our final response to Your complaint.
      </p>
      <h3>16. ENTIRE AGREEMENT</h3>
      <p>
        16.1. This Terms of Use and, where applicable the other Related
        Agreements constitute the entire agreement between You and Us and
        supersede and extinguish all previous agreements, promises, assurances,
        warranties, representations and understandings between Us, whether
        written or oral, relating to its subject matter.
        <br />
        <br />
        16.2. You acknowledge that You do not rely on any statement,
        representation, assurance or warranty (whether made innocently or
        negligently) that is not referenced or set out in this Terms of Use and
        the Related Agreements.
      </p>
      <h3>17. FORCE MAJEURE</h3>
      <p>
        17.1. TradeDepot shall not be in breach of this Terms of Use nor liable
        for delay in performing, or failure to perform, any of its obligations
        under this Terms of Use if such delay or failure results from events,
        circumstances or causes beyond Our control. In such circumstances the
        time for performance shall be extended by a period equivalent to the
        period during which performance of the obligation has been delayed or
        failed to be performed.
      </p>
      <h3>18. ASSIGNMENT</h3>
      <p>
        18.1. You shall not assign, transfer or novate any of Your rights and
        obligations under this Terms of Use or any Related Agreements, without
        the written consent of TradeDepot.
      </p>
      <h3>19. SEVERANCE</h3>
      <p>
        19.1. If any provision of this Terms of Use and the Related Agreements
        is determined by any court of competent jurisdiction or arbitrator to be
        invalid, illegal, or unenforceable to any extent, that provision shall,
        if possible, be construed as though more narrowly drawn, if a narrower
        construction would avoid such invalidity, illegality, or
        unenforceability or, if that is not possible, such provision shall, to
        the extent of such invalidity, illegality, or unenforceability, be
        severed, and the remaining provisions of this Terms of Use and the
        Related Agreements shall remain in effect.
      </p>
      <h3>20. SEVERANCE</h3>
      <p>
        20.1. This Terms of Use and the Related Agreements shall not be
        construed as creating any partnership, joint venture, agency or similar
        relationship between you and TradeDepot other than the relationship with
        respect to carrying out the terms of this Terms of Use and the Related
        Agreements. All rights, duties, obligations, and liabilities of
        TradeDepot and you shall be separate, individual and several and not
        joint or joint and several. Except as set forth in this Terms of Use and
        the Related Agreements, you have no authority to act as agent for
        TradeDepot or bind TradeDepot in any way.
        <br />
        <br />
        For general enquiries regarding this Terms of Use, please feel free to
        contact us at{" "}
        <a href='mailto:enquiries@TradeDepot.co'>
          enquiries@TradeDepot.co
        </a> or{" "}
        <a href='mailto:hello@TradeDepotWholesale.com'>
          hello@tradedepotwholesale.com
        </a>
      </p>
      <h3>21. REMEDIES CUMULATIVE</h3>
      <p>
        21.1. No failure on the part of TradeDepot to exercise, or delay on its
        part in exercising, any right, power or remedy provided by this
        Agreement or by law shall operate as a waiver thereof, nor shall any
        single or partial exercise of any such right, power or remedy preclude
        any further or other exercise of that, or any other, right, power or
        remedy.
      </p>
      <h3>22. NO WAIVER</h3>
      <p>
        22.1. No failure by TradeDepot to exercise, and no delay in exercising,
        any right or remedy in respect of any provision of this Agreement shall
        operate as a waiver of such right or remedy.
      </p>
      <h3>23. GOVERNING LAW & DISPUTE RESOLUTION</h3>
      <p>
        23.1. This Terms of Use and the Related Agreements is made under,
        governed by and to be construed in accordance with the laws of the
        Federal Republic of Nigeria. All disputes related to the Terms of Use
        and Related Agreements, shall be submitted for resolution by the
        relevant courts in Nigeria.
      </p>
      <h3>24. BEWARE OF SCAM</h3>
      <p>
        24.1. Scammers are getting increasingly sophisticated in their attempts
        to have access to Your money, Your financial information and other
        personal information. It is Your obligation to keep Your Information and
        sign -in details safe. Do not share Your TradeDepot Wholesale
        Profile/Wallet details with anyone.
        <br />
        <br />
        24.2. TradeDepot would never:
        <br />
        <br />
        24.2.1. Send You suspicious texts, pop-up windows, links or attachments
        in emails. If You receive any suspicious email/ text purporting to be
        from TradeDepot kindly disregard the email and proceed to inform
        TradeDepot by clicking the “Help” tab on the App.
        <br />
        <br />
        24.2.2. Request Your sign-in details for the purpose of investing the
        funds in Your TradeDepot Wholesale Wallet on Your behalf, and receiving
        free Product/Product trials.
        <br />
        <br />
        24.2.3. Have a tech support call You to inform You of viruses detected
        on Your device, and requesting that you send a screenshot of your QR
        code, click a link or provide Your sign-in details or payment PIN for
        the purpose of providing antivirus software to You.
        <br />
        <br />
        24.2.4. Send an email, SMS, or call You or leave a voice message
        requesting You repay the Cash Advance you obtained from TradeDepot into
        an account provided by the caller or in the SMS, threatening that,
        unless funds are wired immediately, legal action will be taken or you'll
        be arrested. Kindly click here to find out how a Cash Advance obtained
        from TradeDepot can be re-paid.
        <br />
        <br />
        24.2.5. Send an email, SMS, or call You or leave a voice message
        informing you about a refund and requesting that You to provide Your
        sign-in details or payment PIN to claim such refund.
        <br />
        <br />
        24.2.6. Send an email, SMS, or call You or leave a voice message
        informing You that TradeDepot is issuing payment cards and requesting
        personal and financial information, or asking that you click on link
        before being issued a payment card.
        <br />
        <br />
        24.2.7. Send an email, SMS, or call You or leave a voice message
        requesting that you make donations to a church, mosque, charity cause or
        any non-governmental organization.
        <br />
        <br />
        24.2.8. Send an email, SMS, or call You or leave a voice message
        offering You a free gift card and requesting that You provide Your
        sign-in details, payment PIN or click a link to receive the free gift
        card.
        <br />
        <br />
        24.2.9. Request that you provide Your sign -in details, payment PIN to
        dispatch riders or any third party.
        <br />
        <br />
        24.2.10. Ask any third party to request for a Cash Advance on Your
        behalf.
        <br />
        <br />
        24.3. TradeDepot shall bear no liability for any loss of business, loss
        of business opportunity, loss of revenue, loss of profits, loss of
        anticipated savings, loss of goodwill, business interruption, wasted
        expenditure or for loss of any other economic advantage however it may
        arise, or for loss of data or data corruption, or for any indirect,
        punitive, special, incidental or consequential loss, arising as a result
        of the events contemplated in clauses 24.1 -24.2(i)-(x).
        <br />
        <br />
        24.4. In the event that You suspect that You may have fallen victim to
        scammers, You are obliged to immediately contact TradeDepot to place a
        restriction on Your TradeDepot Wholesale Wallet. You shall be
        responsible for such charges and for any transactions that occurred on
        Your TradeDepot Wholesale Profile/Wallet as a result of the information
        or access You granted to scammers. You hereby agree to indemnify and
        hold TradeDepot and its affiliates harmless for any losses, liabilities,
        damages, costs and charges arising out of or in relation to any
        transactions that occurred on Your TradeDepot Wholesale Profile/Wallet
        prior to placing a restriction on Your TradeDepot Wholesale Wallet.
        <br />
        <br />
        24.5. TradeDepot takes reasonable measures in securing all
        communications. However, please note that the confidentiality of
        communications via any public telecommunications network is susceptible
        to unlawful interception and hacking. TradeDepot shall not be liable for
        any loss or damage whether direct or consequential arising out of any
        such unlawful interception or access.
        <br />
        <br />
      </p>
      <h4 className='last-modified'>Last Revised: 8th May, 2024</h4>
    </div>
  );
};

const UKTerms = () => {
  return (
    <div className='trade-uk-terms__content'>
      <h3>1. INTRODUCTION</h3>
      <p>
        1.1. These Terms and Conditions together with our Privacy Policy and
        Shipping and Returns Policy tells you the terms on which you may make
        use of TradeDepot Wholesale’s Mobile Application (Mobile App), Website
        Application (Web App) and the WhatsApp messaging service (“WhatsApp
        Service”) whether as a guest or a registered user. Use of the TradeDepot
        Wholesale Mobile App or Web App includes accessing, browsing, or
        registering to use the TradeDepot Wholesale Mobile App or Web App.
        <br />
        <br />
        1.2.Please read these Terms and Conditions carefully before you start to
        use the TradeDepot Wholesale Mobile App or Web App and WhatsApp
        messaging service. If you do not agree to these Terms and Conditions,
        you must not use the TradeDepot Wholesale Mobile App or Web App.
        <br />
        <br />
        1.3. TradeDepot Wholesale adheres to a strict set of Terms & Conditions.
        When ordering from TradeDepot Wholesale for commercial purposes Consumer
        Regulations do not apply and The Customer agrees to be bound by the
        following Business Terms & Conditions.
        <br />
        <br />
        1.4. The following terminology applies to these Terms and Conditions,
        Privacy Policy, Shipping and Returns policy and any or all Agreements:
        "Client", “Customer”, “You” and “Your” refers to you, the person
        accessing this web app and/or mobile app and accepting the Company’s
        terms and conditions. “Frontier Africa Trading Co Ltd’’, “Frontier
        Africa’’, “The TradeDepot Wholesale’’, “TradeDepot Wholesale’’,
        “TradeDepot", “The Company", “Ourselves”, “We”, “Our” and "Us", refers
        to our Company, Frontier Africa Trading Co Ltd trading as TradeDepot.
        “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or
        either the Client or ourselves. Any use of the above terminology or
        other words in the singular, plural, capitalisation and/or he/she or
        they, are taken as interchangeable and therefore as referring to same.
      </p>
      <h3>2. About Us</h3>
      <p>
        2.1. TradeDepot Wholesale is operated by TradeDepot, the trading style
        of Frontier Africa Trading Co Ltd, a company registered in England,
        whose registered address is at 200 Brook Drive, Reading, United Kingdom
        RG2 6UB. Our company registration number is 14788482.
      </p>
      <h3>3. Changes to these terms</h3>
      <p>
        3.1. We reserve the right to change these terms and conditions from time
        to time in Our absolute discretion in order to comply with the operation
        of law, regulation, guidance, a decision of the court or equivalent body
        or as We otherwise see fit. Check this page from time to time to take
        notice of any changes We may make, as they are binding on you. We may
        update the TradeDepot Wholesale mobile app and web app from time to
        time, and may change the content at any time.
      </p>
      <h3>4. Accessing TradeDepot Wholesale</h3>
      <p>
        4.1. You hereby declare that you are a human being over the age of 18
        years and of sound mind. Your TradeDepot Wholesale profile may be
        deleted without notice if We believe in Our sole discretion that you are
        less than 18 years of age.
        <br />
        <br />
        4.2. The TradeDepot Wholesale mobile app and web app is made available
        free of charge. You can download the app from the Google Playstore or
        the Apple App Store and accept the license agreement. You are
        responsible for making all arrangements necessary to access TradeDepot
        Wholesale.
        <br />
        <br />
        4.3. We do not guarantee that TradeDepot Wholesale, or any content on
        it, will always be available or be uninterrupted and does not guarantee
        that they or any content on it will be free from errors or omissions.
        Access to TradeDepot Wholesale is permitted on a temporary basis. We may
        suspend, withdraw, discontinue or change all or any part of the
        TradeDepot Wholesale without notice. We will not be liable to you if for
        any reason TradeDepot Wholesale is unavailable at any time or for any
        period.
        <br />
        <br />
        4.4. You are also responsible for ensuring that all persons who access
        TradeDepot Wholesale through your internet connection are aware of these
        Terms and Conditions and other applicable terms and conditions, and that
        they comply with them.
        <br />
        <br />
        4.5. TradeDepot Wholesale is directed to businesses in the United
        Kingdom. We do not represent that content available on or through
        TradeDepot Wholesale is appropriate or available in other locations. If
        you choose to access the TradeDepot Wholesale from outside the United
        Kingdom, you do so at your own risk.
      </p>
      <h3>5. Your account and password</h3>
      <p>
        5.1. If you choose, or you are provided with, a customer account,
        password, pin, passcode or any other piece of information as part of our
        security procedures, you must treat such information as confidential.
        You must not disclose it to any third party.
        <br />
        <br />
        5.2. We have the right to disable any customer account or password,
        whether chosen by you or allocated by us, at any time, if in our
        reasonable opinion you have failed to comply with any of the provisions
        of these Terms and Conditions.
        <br />
        <br />
        5.3. If you know or suspect that your account details, password, pin or
        passcode are no longer confidential then you must reset your password,
        pin or passcode and promptly notify us by sending an email or via the
        Chat function in the mobile and web app.
      </p>
      <h3>6. Intellectual property rights</h3>
      <p>
        6.1. TradeDepot is the owner or the licensee of all intellectual
        property rights in the TradeDepot Wholesale web app and mobile app and
        in the material published on it.
        <br />
        <br />
        6.2. You must not modify any materials you have printed off or
        downloaded in any way, and you must not use any illustrations,
        photographs, video or audio sequences or any graphics separately from
        any accompanying text.
        <br />
        <br />
        6.3.You must not use any part of the content on TradeDepot Wholesale for
        commercial purposes other than to purchase our products and services.
      </p>
      <h3>7. Acceptable use</h3>
      <p>
        7.1.Access to our product listings and prices are limited to registered
        customers only and are confidential to our customers and may not be
        disclosed to any third party.
        <br />
        <br />
        7.2. You may not use the TradeDepot Wholesale web app and/or mobile app:
        <br />
        <br />
        7.2.1. In any way that breaches any applicable local, national or
        international law or regulation;
        <br />
        <br />
        7.2.2. In any way that is unlawful or fraudulent, or has any unlawful or
        fraudulent purpose or effect;
        <br />
        <br />
        7.2.3. To knowingly transmit any data, send or upload any material that
        contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
        spyware, adware or any other harmful programs or similar computer code
        designed to adversely affect the operation of any computer software or
        hardware;
        <br />
        <br />
        7.2.4. To reproduce, duplicate, copy, display or sell any part of
        TradeDepot Wholesale or its content or use any content other than for
        the purchase of our products and services and may not use any data
        mining, data robots or any other similar tool for any purpose;
        <br />
        <br />
        7.2.5. Access without authority, interfere with, damage or disrupt any
        part of TradeDepot Wholesale mobile/web app, any equipment or network on
        which TradeDepot Wholesale mobile/web app is stored or any software used
        in the provision of TradeDepot Wholesale’s mobile/web app.
        <br />
        <br />
        7.3 In the event of a breach of the Terms and Conditions of TradeDepot
        Wholesale’s mobile/web app then we may at Our discretion withdraw Your
        right to use TradeDepot Wholesale’s mobile/web app and take any action
        against You we deem appropriate.
      </p>
      <h3>8. Sales Conditions and Customers</h3>
      <p>
        8.1. The following Conditions are the only conditions on which We sell
        goods. Please ensure You understand them before You place an order or
        buy Goods from Us. Unless otherwise mutually agreed in writing they will
        apply notwithstanding any other terms on which You wish to purchase
        Goods from Us. Our representatives are not authorised to agree to any
        other conditions or to vary these Conditions.
        <br />
        <br />
        8.2. These Conditions supersede any previous conditions between You and
        Us (whether oral or in writing) and also supersede any previous course
        of dealing, trade custom or understanding between You and Us.
        <br />
        <br />
        8.3.In these Conditions “Goods” means all goods sold by Us to You.
        “Contract” means the contract between Us and You for the sale and
        purchase of Goods in accordance with these Conditions.
        <br />
        <br />
        8.4. These Conditions cover: sales of Goods to You where an order is
        placed via Our TradeDepot Wholesale website and/or mobile application
        for delivery of Goods. You can use Our web app and mobile app to place
        an order by selecting the Goods You wish to buy and adding it to Your
        app cart. Goods You do not require can be removed from Your cart at any
        time. Your order will be completed on the mobile/web app by checking out
        the items on your cart and choosing your appropriate delivery and
        payment method.
        <br />
        <br />
        8.5. Sales transactions through the TradeDepot Wholesale web app and
        mobile app are fulfilled on the agreement that You are not a consumer.
        This means that You are accessing our site and shall enter a contract
        with us by requesting the supply of products as part of Your business
        and Your deliveries will be made to a business address.
        <br />
        <br />
        8.6. We reserve the right to refuse Your custom, suspend sale or
        delivery, or cancel any order in the following circumstances or event:
        <br />
        <br />
        8.6.1. that circumstances arise which, in Our view, oblige Us to do so;
        <br />
        <br />
        8.6.2. that Our accounts are not paid to terms;
        <br />
        <br />
        8.6.3. that payments are not received from a registered customer or
        other authorised payment provider or agent;
        <br />
        <br />
        8.6.4. Your insolvency or that that You cease or threaten to cease
        business or enter into voluntary arrangement or become bankrupt;
        <br />
        <br />
        8.6.5. Our inability through shortage of stocks or for any other reason
        to execute delivery wholly or in part; and
        <br />
        <br />
        8.6.6. in Our absolute discretion there is a risk that to proceed with
        such activity may result in a breach of any law, rule or regulation of
        any jurisdiction including but not limited to bribery, corruption,
        money-laundering, terrorist financing or the breach of any sanctions
        related laws or rules including any economic, financial or trade laws or
        regulations enacted or enforced by any jurisdiction.
        <br />
        <br />
        8.7. We reserve the right to make reasonable alterations to products
        and/or packaging as circumstances may demand. We will not be liable for
        costs, damages, losses or expenses of any nature resulting from part
        delivery, suspension of delivery, reduction or cancellation of orders.
        <br />
        <br />
        8.8. In the event We suspend sale or delivery or refuse to accept orders
        placed by You due to one of the events in 8.6 above then without
        limiting any other rights available to Us, any Goods that have been
        delivered but not paid for shall become immediately due for payment
        notwithstanding any previous agreement or arrangement to the contrary.
        <br />
        <br />
        8.9. Where You are a sole trader You will be personally liable to make
        payment for Goods and You hereby guarantee and acknowledge personal
        liability for payment of the Goods and interest chargeable in accordance
        with these Conditions and no waiver that might be extended in respect of
        these Conditions shall affect Your liability hereunder. In the event You
        become a partnership or limited company then You will still remain
        personally jointly and/or severally liable for payments.
        <br />
        <br />
        8.10. Where You are a partnership or a limited company, an individual or
        individual(s) with authority to bind You will be personally liable to
        make payment for Goods and interest chargeable in accordance with these
        Conditions and no waiver that might be extended in respect of these
        Conditions shall affect Your liability hereunder. Where You are a
        partnership, these Conditions are binding on all of the partners with
        joint and several liability and no waiver shall affect their individual
        liability. You shall be obliged to notify Us of any change in the
        partners of Your business.
        <br />
        <br />
        8.11. We may collect information from you in order to prevent and detect
        fraud, money laundering and other crime.
        <br />
        <br />
        8.12. We reserve the right to take any action that is required by Us in
        order to comply with any law, regulation (including guidance from
        regulators) or an order of the court. This includes but is not limited
        to bribery, corruption, money-laundering, terrorist financing or the
        breach of any sanctions related laws or rules including any economic,
        financial or trade laws or regulations enacted or enforced by any
        jurisdiction.
      </p>
      <h3>9. Prices</h3>
      <p>
        9.1. Prices for Our Goods are displayed on Our web app and mobile app.
        All prices are applicable on the date of Your order and as set out in
        Your invoice.
        <br />
        <br />
        9.2. If You have been charged an incorrect price We reserve the right to
        rectify Our invoice provided only that any claim in respect of incorrect
        prices is brought to Our attention in writing no later than one working
        day from the date of the invoice.
        <br />
        <br />
        9.3. We reserve the right to alter Prices without notice to reflect
        increase in costs to Us.
        <br />
        <br />
        9.4. Prices are in the British Pound (GBP) and exclusive of VAT. VAT
        will be charged on the supply of Goods at the rate prevailing at the tax
        point date.
        <br />
        <br />
        9.5. Delivery charges and a processing fee might also be charged and
        will be shown on Our Mobile app and Web App prior to you placing Your
        order.
      </p>
      <h3>10. Orders, Delivery and Acceptance</h3>
      <p>
        10.1. We reserve the right to refuse orders for any reason, including
        (but not limited to) those considered economically non-viable, or
        hazardous to our vehicles or drivers, or where We have reason to believe
        that You are involved in illegal activity or as required by the
        operation of law, regulation, guidance or a decision of the court or
        equivalent body. There is no Contract between Us and You until We
        confirm acceptance of Your order. Once an order is accepted by Us, We
        specifically reserve the right to cancel or suspend an order in the
        event of non-payment for any Goods previously ordered by You. Each order
        is a separate contract and You are responsible for ensuring the accuracy
        of the order submitted and for giving Us all the information We need to
        process the order.
        <br />
        <br />
        10.2. <b>Minimum Order Values:</b> Minimum order values for Goods
        (excluding VAT) are in effect and can be seen on our Mobile and Web App.
        <br />
        <br />
        10.3. <b>Delivery Charges:</b> You will be charged for delivery of Goods
        to You at the point of placing Your order on Our mobile or web
        application. Delivery charges are displayed on TradeDepot Wholesale’s
        mobile and web application.
        <br />
        <br />
        10.4. <b>Order Cancellation:</b> Once an order is created on Our mobile
        and web app, You will be unable to cancel it. Once the goods are
        received, You can initiate the returns process for the unwanted items.
        Please refer to Our shipping and returns policy for more information.
        <br />
        <br />
        10.5. All delivery dates quoted or agreed by Us are estimates and are
        not of the essence of the contract between Us. Delivery of the Goods
        shall be completed on the Goods arrival at Your store.
        <br />
        <br />
        10.6. You have 7 calendar days from the date of delivery to examine all
        Goods delivered to you. You will not have any claim in respect of the
        Goods once this time has elapsed.
      </p>
      <h3>11. Property and Risk</h3>
      <p>
        11.1. Risk in the Goods shall pass to You at the time of delivery of the
        Goods to You or Your agent or when You have paid in full for the Goods,
        whichever is the earlier and You hereby undertake to insure the Goods to
        their full value for all risks and liability including fire and theft.
        <br />
        <br />
        11.2. Title in the Goods will only pass to You when We have received
        cleared payment in full for the Goods plus VAT and/or any other payment
        howsoever due to Us from You.
        <br />
        <br />
        11.3. Until all payments due for the Goods have been received:
        <br />
        <br />
        11.3.1. full legal and equitable title shall remain with Us and
        <br />
        <br />
        11.3.2. You shall hold the Goods as bailee and shall be required to
        store the Goods in such a manner as to preserve their value, in such a
        way that they may be clearly identified as Our property and separate
        from Your own property or the property of any other person or entity and
        <br />
        <br />
        11.3.3. Although the Goods remain Our property until paid for, they are
        at Your risk from the time of delivery and You shall hold the proceeds
        of any insurance against loss or damage as trustee for Us and
        <br />
        <br />
        11.3.4. We may trace any proceeds of sale of our Goods into any account
        which You maintain and
        <br />
        <br />
        11.3.5. You must not assign any rights arising from the sale of such
        Goods without Our consent.
        <br />
        <br />
        11.4. If You breach clause 11.3 then We are entitled to compensatory
        damages but You may resell or Use the Goods on the condition that as
        long as You have not paid in full for the price of those Goods, You
        shall be able to account to Us with the proceeds of the sale of those
        Goods which, shall be kept in a separate account. If You sell the Goods
        before You have paid for them in full, You sell them as principal and
        not as Our agent and title to the Goods shall pass from Us to You
        immediately before the time at which You sell them.
        <br />
        <br />
        11.5. If payment is overdue in whole or in part, or upon commencement of
        any proceedings for insolvency, We may (without prejudice to any of Our
        other rights) recover or recall the Goods or any of them and We (and Our
        agents) may enter upon Your premises or any premises where the Goods are
        stored for such purpose and You hereby grant Us (and Our agents) a
        licence to enter upon any premises occupied or controlled by You so as
        to recover or inspect such Goods, title to which has not passed to You
        (irrespective of whether such Goods can be specified, or attributed to
        corresponding purchase orders or delivery notes and without regard to
        sub-clause 12.3 below) to the value of the amount due and payable.
        <br />
        <br />
        11.6. You may not for any indebtedness pledge or in any way charge by
        way of security (including without limitation fixed and floating
        charges) any of the Goods which remain Our property. Without prejudice
        to Our other rights, if You do so, all sums owing by You to Us shall
        forthwith become due and payable.
        <br />
        <br />
        11.7. Your right to possession of the Goods shall cease if upon the
        presentation to a bank of any cheque tendered in respect of payment for
        Goods it is returned or dishonoured or You have not paid for the Goods
        in full by the expiry of a credit period allowed by Us under a Credit
        Sales Agreement or You are declared bankrupt or make any proposal to
        Your creditors for a composition or other voluntary arrangement or a
        receiver, liquidator or administrator is appointed in respect of Your
        business. If Your right to possession of the Goods ceases pursuant to
        this clause, You shall at Your own expense make the Goods available to
        Us and allow us to repossess them.
      </p>
      <h3>12. Credit and Payment</h3>
      <p>
        12.1. You may apply for but We are not obliged to give You the ability
        to make payment for Goods by credit for a specified time period and
        financial limit (the ‘Relevant Applicable Credit Limit’) and subject to
        such other terms as We agree.
        <br />
        <br />
        12.2. You must give true and accurate information on any application for
        a credit facility and acknowledge and agree to Us processing Your data
        in accordance with Our Privacy Policy including sharing Your information
        and data with credit reference agencies. Where You provide Us with
        information in relation to Your business partners or directors then You
        must obtain their consent to disclose this information to Us.
        <br />
        <br />
        12.3. We may at any time in Our discretion and without notice, vary Your
        Relevant Applicable Credit Limit or withdraw any credit or cheque
        payment facility.
        <br />
        <br />
        12.4. Unless a credit limit has been extended to You, cash payment is
        required at the point of placing an order on Our Web or Mobile app. An
        invoice will be available for download on our Mobile and Web application
        once your items have been delivered. Credit payments are in accordance
        with the specific credit terms agreement and will be payable on the date
        specified on any invoice. Time of payment is of the essence.
        <br />
        <br />
        12.5. If at any time any invoices are overdue or Your indebtedness to Us
        exceeds the Relevant Applicable Credit Limit then all unpaid balances
        owing to Us from You shall become immediately due and payable.
        <br />
        12.6. If You fail to make any payment due to Us in accordance with these
        terms then You shall pay interest on the overdue amount at the rate of
        4% per annum above the Bank of England’s base rate chargeable both
        before and after any proceedings on the amount unpaid accruing on a
        daily basis, until payment is made in full. You shall pay the interest
        together with the overdue amount.
        <br />
        <br />
        12.7. We may also charge You interest at the rate prescribed by and
        compensation due in accordance with the Late Payment of Commercial Debts
        (Interest) Act 1998 and the Late Payment of Commercial Debts Regulations
        2002.
        <br />
        <br />
        12.8. You shall pay all and any costs and expenses incurred by Us in
        connection with and/or in relation to the recovery of debt, sums and or
        damages from You including but not limited to the costs of issuing a
        formal letter before action and any legal costs incurred in relation to
        actual or prospective legal proceedings by Us against You.
        <br />
        <br />
        12.9. You shall pay all amounts due under the Contract in full without
        any set-off, counterclaim, deduction or withholding (except for any
        deduction or withholding required by law). We may at any time, without
        limiting any other rights or remedies We may have, set off any amount
        owing to Us by You against any amount payable by the Us to You.
        <br />
        <br />
        12.10. We reserve the right to withdraw any credit facilities and
        suspend deliveries if circumstances arise which in Our view oblige Us to
        do so or as required by the operation of law, regulation, guidance or a
        decision of the court or equivalent body.
      </p>
      <h3>13. Open Date Marketing</h3>
      <p>
        13.1. No Goods are offered on a sale or return basis. Our
        representatives are not authorised to accept orders on a sale or return
        basis. It is therefore Your responsibility to sell the Goods prior to
        the expiry of any “sell by”, “best before”, “use by” or similar date.
        <br />
        <br />
        13.2. We reserve the right to amend the specification of any Goods if
        required by any applicable statutory or regulatory requirements.
      </p>
      <h3>14. Consumer Complaints</h3>
      <p>
        14.1. Any consumer complaint concerning any of Our Goods must be
        referred directly to Us and We will manage it. We will not accept
        responsibility for any payment made by You to a consumer in settlement
        of any such claim.
      </p>
      <h3>15. Warranties and Liabilities</h3>
      <p>
        15.1. Nothing in these Conditions shall affect Our implied undertakings
        given to You under S12 Sale of Goods Act 1979 or Your statutory rights
        under the Agriculture Act 1970 (as amended).
        <br />
        <br />
        15.2. Nothing in these Conditions shall exclude or limit Our liability
        for death or personal injury resulting from Our negligence, fraud or
        fraudulent misrepresentation or defective products under the Consumer
        Protection Act 1987.
        <br />
        <br />
        15.3. All Goods are warranted by Us on delivery to comply with all
        relevant UK food law from time to time in force. No warranty is given
        that the Goods comply with food and other relevant legislation or do not
        infringe third party rights outside the UK. We warrant that the Goods
        are sold with good title and comply with Our description and
        specification of them.
        <br />
        <br />
        15.4. Save as set out in clauses 15.1 to 15.3, all conditions,
        warranties, guarantees and representations (both innocent and negligent)
        whether express or implied by law custom or trade or otherwise are
        excluded.
        <br />
        <br />
        15.5. Our liability to You under or in any way related to the sale and
        purchase of Goods or otherwise whether involving Our negligence or not
        shall only extend to giving an appropriate credit for or repayment of
        the price paid by You for defective Goods. Subject to clauses 15.1 to
        15.3 We shall not be liable, whether in contract, tort (including
        negligence), breach of statutory duty or otherwise for any loss of
        profit, or any indirect or consequential loss arising under or in
        connection with the Contract, any loss of turnover or of goodwill, or
        any damage to Your property.
        <br />
        <br />
        15.6. Nothing in these conditions shall affect Your duty to mitigate
        Your losses. Save as set out above We shall not have any liability
        whatsoever to You under or if any way related to the sale and purchase
        of the Goods or otherwise (whether in contract, tort (including without
        limitation negligence) or by way of statutory duty) for any claims, loss
        or damage of any nature whatsoever including without limitation
        consequential losses of any nature whatsoever.
        <br />
        <br />
        15.7. You shall indemnify Us in full for all liability, loss, damages,
        costs and expenses (including legal expenses) awarded against or
        incurred by or paid by Us as a result of or in connection with any
        breach or non-compliance with any of the terms of these Conditions.
        <br />
        <br />
        15.8. You shall at all times supply full and accurate information as
        required by us relating to Your business, Your personal details and any
        personal details of Your agents and representatives.
        <br />
        <br />
        15.9. You warrant and represent that You shall comply with all laws,
        enactments, regulations, regulatory policies, guidelines and industry
        codes applicable to You in ordering and purchasing product from Us and
        shall maintain such authorisations and all other approvals, permits and
        authorities as are required from time to time to perform Your
        obligations under or in connection with the order and purchase of Our
        products including but not limited to;
        <br />
        <br />
        15.9.1. all laws, rules and regulations of any jurisdiction relating to
        bribery and corruption;
        <br />
        <br />
        15.9.2. all economic sanctions, financial or trade laws, regulations,
        embargoes or restricted measures administered, enacted or enforced by
        the Office of Foreign Assets Control of the US Department of State, or
        by the United Nations Security Council or the European Union.
        <br />
        <br />
        15.10. Nothing in these Terms of Use excludes or limits our liability
        for death or personal injury arising from our negligence, or our fraud
        or fraudulent misrepresentation, or any other liability that cannot be
        excluded or limited by English law. To the extent permitted by law, we
        exclude all conditions, warranties, representations or other terms which
        may apply to TradeDepot Wholesale or any content on it, whether express
        or implied.
        <br />
        <br />
        15.11. We will not be liable to any user for any loss or damage, whether
        in contract, tort (including negligence), breach of statutory duty, or
        otherwise, arising under or in connection with use of, or inability to
        use TradeDepot Wholesale, use of or reliance on any content displayed on
        TradeDepot Wholesale. We will not be liable for any loss of profits,
        business interruption, loss of business opportunity or any indirect or
        consequential loss or damage.
        <br />
        <br />
        15.12. We will not be liable for any loss or damage caused by a virus,
        distributed denial-of-service attack, or other technologically harmful
        material that may infect your computer equipment, computer programs,
        data or other proprietary material due to your use of the TradeDepot
        Wholesale or to your downloading of any content on it, or on any mobile
        or web app linked to it.
        <br />
        <br />
        15.13. We assume no responsibility for the content of websites linked on
        TradeDepot Wholesale. Such links should not be interpreted as
        endorsement by us of those linked websites. We will not be liable for
        any loss or damage that may arise from your use of them.
      </p>
      <h3>16. Proprietary Marks</h3>
      <p>
        16.1. You acknowledge that the goodwill and other rights in any patents,
        trade marks, trade names, copyright designs, know-how or other
        intellectual property right used and adopted by Us and any logo or
        symbol associated with Our name vest in Us and shall remain vested in
        Us.
        <br />
        <br />
        16.2. You must not in any way use or display or make any statement or
        representation about any of Our proprietary marks unless We authorise
        You to do so, threaten or take any action to contest the validity of Our
        marks or sell, assign, transfer, charge or otherwise deal with Our
        marks.
        <br />
        <br />
        16.3. No right or license is granted under these Terms under any patent,
        trade mark, trade name, copyright, designs, know-how or other
        intellectual property right except the right to use or resell the Goods.
        We cannot guarantee that selling the Goods will affect the rights of any
        third party.
      </p>
      <h3>17. Force Majeure</h3>
      <p>
        17.1. Notwithstanding any other condition We shall have no liability
        whatsoever under or in any way related to the sale and purchase of the
        Goods or otherwise (whether in contract, tort (including without
        limitation negligence) or by way of statutory duty) for any failure to
        fulfil any obligation hereunder if and to the extent that such
        fulfilment is prevented by circumstances beyond Our reasonable control.
      </p>
      <h3>18. No Reliance on Information</h3>
      <p>
        18.1. Although we make reasonable efforts to update the information on
        TradeDepot Wholesale, we make no representations, warranties or
        guarantees, whether express or implied, that the content on TradeDepot
        Wholesale is accurate, complete or up-to-date.
      </p>
      <h3>19. Uploading Content to the TradeDepot Wholesale</h3>
      <p>
        19.1. Whenever you upload content to TradeDepot Wholesale, you must
        comply with the provisions in these terms and conditions. You warrant
        that such content does comply with those terms and that you will be
        liable to us and indemnify us for any breach of that warranty.
        <br />
        <br />
        19.2. We reserve the right to remove any content that does not comply
        with these Terms and Conditions.
      </p>
      <h3>20. Viruses</h3>
      <p>
        20.1. We do not guarantee that TradeDepot Wholesale will be secure or
        free from bugs or viruses.
        <br />
        <br />
        20.2. You are responsible for configuring your information technology,
        computer programmes and platform in order to access TradeDepot
        Wholesale. You should use your own virus protection software.
        <br />
        <br />
        20.3. You must not misuse TradeDepot Wholesale by knowingly introducing
        viruses, trojans, worms, logic bombs or other material which is
        malicious or technologically harmful. You must not attempt to gain
        unauthorised access to TradeDepot Wholesale, the server on which
        TradeDepot Wholesale is stored or any server, computer or database
        connected to TradeDepot Wholesale. You must not attack TradeDepot
        Wholesale via a denial-of-service attack or a distributed denial-of
        service attack. By breaching this provision, you would commit a criminal
        offence under the Computer Misuse Act 1990. We will report any such
        breach to the relevant law enforcement authorities and we will
        co-operate with those authorities by disclosing your identity to them.
        In the event of such a breach, your right to use TradeDepot Wholesale
        will cease immediately.
      </p>
      <h3>21. Links</h3>
      <p>
        21.1. You shall not link to our mobile application, home page, or
        website pages without our written consent. Where TradeDepot Wholesale
        contains links to other sites and resources provided by third parties,
        these links are provided for your information only. We have no control
        over the contents of those sites or resources.
      </p>
      <h3>22. General</h3>
      <p>
        22.1. These Conditions shall be governed by the laws of England and You
        agree to submit to the exclusive jurisdiction of the English courts.
        <br />
        <br />
        22.2. The Contract constitutes the entire agreement between Us and You.
        You acknowledge that You have not relied on any statement, promise,
        representation, assurance or warranty made or given by or on behalf of
        Us which is not set out in a Contract.
        <br />
        <br />
        22.3. No waiver by Us of any breach of these Conditions by You shall be
        considered a breach as a waiver of any subsequent breach.
        <br />
        <br />
        22.4. If any provision of these Conditions is held by any court to be
        invalid or unenforceable in whole or in part the validity of the other
        provisions of these Conditions or the remainder of the provision in
        question shall not be affected.
        <br />
        <br />
        22.5. You must not pledge Our credit nor represent Yourself as being Us
        nor Our agent, partner or employee and must not hold Yourself out as
        having any such authority or power to incur any obligations on Our
        behalf.
        <br />
        <br />
        22.6. Subject to clause 22.3, any person who is not a party to a
        Contract between Us shall not have any rights to enforce its terms.
      </p>
      <h3>23. Contact Us</h3>
      <p>
        23.1 To contact us, you can use the chat function on our mobile or web
        application or send us an email at{" "}
        <a href='mailto:support.uk@tradedepot.co'>support.uk@tradedepot.co</a>,
        or chat with us on WhatsApp on{" "}
        <a href='https://wa.me/+447999020000' target='_blank' rel='noreferrer'>
          +44 7999 020000
        </a>
      </p>
      <h4 className='last-modified'>Last Revised: 27th Feb, 2024</h4>
    </div>
  );
};
