/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const LandingTwo = () => {
  console.log("windd", window);
  return (
    <>
      <div className="trade-landing">
        <div className="trade-landing__ban">
          <nav className="navbar navbar-expand-lg navbar-dark absolute-top opaque-navbar trade-landing__full">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img src="images/logo.png" width="200" alt="" />
              </a>
              <button
                className="navbar-toggler navbar-toggler-right"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#"
                      onClick={() => null}
                      onMouseDown={() => window.autoScrollTo("benefits")}
                    >
                      Benefits
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#"
                      onclick="return false;"
                      onmousedown="autoScrollTo('howitworks');"
                      onClick={() => null}
                      onMouseDown={() => window.autoScrollTo("howitworks")}
                    >
                      How It Works
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="#"
                      onclick="return false;"
                      onmousedown="autoScrollTo('stories');"
                    >
                      Success Stories
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://app.tradedepot.co/">
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="typeform-share button trade-landing__ban__btn"
                      href="https://tradedepot.typeform.com/to/YpDE0w"
                      data-mode="drawer_right"
                      data-submit-close-delay="5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign Up{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="trade-landing__mobile">
            <div className="container">
              <div className="trade-landing__topnav">
                <img src="images/logo.png" width="200" alt="" />
                <div id="myLinks">
                  <a
                    href="#"
                    onclick="return false;"
                    onmousedown="autoScrollTo('benefits');"
                  >
                    Benefits
                  </a>
                  <a
                    href="#"
                    onclick="return false;"
                    onmousedown="autoScrollTo('howitworks');"
                  >
                    How It Works
                  </a>
                  <a
                    href="#"
                    onclick="return false;"
                    onmousedown="autoScrollTo('stories');"
                  >
                    Success Stories
                  </a>
                  <a href="https://app.tradedepot.co/">Login</a>
                  <a href="https://tradedepot.typeform.com/to/YpDE0w">
                    Sign Up
                  </a>
                </div>
                <a
                  href="javascript:void(0);"
                  className="icon"
                  onClick={() => window.myFunction()}
                >
                  <i className="fa fa-bars"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="trade-landing__ban__title">
              <div className="container">
                <div className="row mt-5">
                  <div className="col-lg-7 text-left my-5">
                    <h1>
                      Grow your business
                      <br />
                      with TradeDepot
                    </h1>
                    <p className="mt-3">
                      When you register as a supplier, TradeDepot becomes a
                      full-time distributor of your products. We buy and store
                      the inventory, and take care of everything from shipping
                      and pricing to customer service and returns.
                    </p>
                    <a
                      className="typeform-share button trade-landing__ban__title__btn"
                      href="https://tradedepot.typeform.com/to/YpDE0w"
                      data-mode="drawer_right"
                      data-submit-close-delay="5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign Up{" "}
                    </a>

                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="trade-landing__ban__brand">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <img src="images/1.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/3.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/4.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/5.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/8.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/7.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/2.png" alt="" />
                  </div>
                  <div className="col">
                    <img src="images/9.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-msd">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-7 d-flex flex-column align-items-center">
              <h1>Reach more stores</h1>

              <p className="mt-3">
                Every 3 minutes, a new retail store gets activated on the
                TradeDepot network. Get your products on the shelves of many
                more outlets and watch your business grow
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-benefits">
        <div className="trade-benefits__pattern"></div>
        <div className="container">
          <div className="row d-flex align-items-stretch justify-content-end">
            <div className="col-12 col-md-4">
              <div className="trade-benefits__cards">
                <img src="./images/millions.svg" alt="" />
                <h1>
                  Reach <br />
                  Millions
                </h1>
                <p>
                  Fresh new startups and Fortune 500s. B2B and B2C. Brand owners
                  and resellers sell on TradeDepot for a reason: millions of
                  customers shop our stores worldwide.
                </p>

                <a href="" className="how-arrow">
                  How it works <img src="./images/forward.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="trade-benefits__cards">
                <img src="./images/deliver.svg" alt="" />
                <h1>
                  Deliver <br />
                  Smiles
                </h1>
                <p>
                  Leave the shipping, returns, and customer service to us with
                  Fulfillment by TradeDepot. Want to take care of shipping
                  yourself? You can do that too.
                </p>

                <a href="" className="how-arrow">
                  How it works <img src="./images/forward.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="trade-benefits__cards">
                <img src="./images/money.svg" alt="" />
                <h1>
                  Make <br />
                  Money
                </h1>
                <p>
                  Choose from flexible selling plans, product categories, and
                  fulfillment options that fit your business needs.
                </p>

                <a href="" className="how-arrow">
                  How it works <img src="./images/forward.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-success-story">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="trade-success-story__heading">
                <h3>Success Stories</h3>
                <p>Connecting Informal Retailers to a Digital Future</p>
              </div>

              <div className="quote--container">
                <p className="quote">
                  There is no exquisite beauty&hellip; without some{" "}
                  <span className="quote--highlight">strangeness</span> in the
                  proportion.
                </p>
                <p className="quote--author">&ndash; Francis Bacon</p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="trade-success-story__video">
                <iframe
                  title="trade-success-story"
                  width="100%"
                  height="530"
                  className="mt-3"
                  src="https://www.youtube.com/embed/N10IsvmaEwc"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-success-steps">
        <div className="trade-success-steps__pattern-top"></div>
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-md-8">
              <div className="trade-success-steps__heading">
                <h4>
                  So how does Redistribution <br /> by TradeDepot works
                </h4>
              </div>
            </div>
          </div>

          <div className="step-wrap">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-5">
                <div className="trade-success-steps__list">
                  <div className="trade-success-steps__list__step">
                    <img src="./images/reg-1.svg" alt="" />
                    <h4>Register</h4>
                    <p>
                      Sign up​ with your contact details. A fulfilment advisor
                      will work with you to identify the ideal assets to deploy
                      in each location, and map each vehicle to a supply
                      territory and distributor pick up point.
                    </p>
                  </div>
                  <div className="trade-success-steps__list__step">
                    <img src="./images/reg-2.svg" alt="" />
                    <h4>Add Products</h4>
                    <p>
                      Add your products to TradeDepot’s Shop TopUp catalog.
                      Indicate which products are available for each supply
                      territory, and at what price. Your fulfilment advisor will
                      work with you to ensure the right product details and pack
                      shots are setup for each product and SKU
                    </p>
                  </div>
                  <div className="trade-success-steps__list__step">
                    <img src="./images/reg-3.svg" alt="" />
                    <h4>We Sell</h4>
                    <p>
                      Once your products are active on the catalog and the
                      vehicles are deployed to the fleet, orders start coming in
                      immediately from tens of thousands of retailers on our
                      network.
                    </p>
                  </div>
                  <div className="trade-success-steps__list__step last-step">
                    <img src="./images/reg-4.svg" alt="" />
                    <h4>We Ship and Pay You!</h4>
                    <p>
                      TradeDepot picks up products from the distributor pick up
                      point and ships to the customer. Payment for the orders
                      (net of TradeDepot seller fees) is deposited into your
                      designated bank account(s), and you receive notification
                      by email that your payment has been sent
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="trade-success-steps__pattern-bottom"></div>
      </div>

      <div className="trade-footer">
        <div className="trade-footer__header">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-12 text-left">
                <div
                  className=""
                  style={{
                    borderBottom: "0.5px solid #152c531f",
                    paddingBottom: "2rem",
                  }}
                >
                  <h1>Start selling today</h1>
                  <p>
                    Put your products on the shelves of the hundreds of
                    thousands of retailers we service every day
                  </p>
                  <a
                    className="typeform-share button trade-footer__header-btn mr-3"
                    href="https://tradedepot.typeform.com/to/YpDE0w"
                    data-mode="drawer_right"
                    data-submit-close-delay="5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sign Up{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="trade-footer__content">
          <div className="trade-footer__pattern"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 pb-5">
                <p>
                  TradeDepot enables Factory-to-Retail distribution for Consumer
                  Goods companies.
                </p>
                <a
                  href="https://www.facebook.com/tradedepot.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-2x fa-facebook-square"></i>
                </a>
                <a
                  href="https://twitter.com/tradedepotHQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-2x fa-twitter-square"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/tradedepot/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-2x fa-linkedin-square"></i>
                </a>
                <a
                  href="https://www.instagram.com/tradedepot.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-2x fa-instagram"></i>
                </a>
              </div>
              <div className="col-lg-2 pb-5"></div>
              <div className="col-lg-3 pb-5 pd-c">
                <h6>COMPANY</h6>
                <a href="/culture">Our Culture</a>
                <br />
                <a href="/careers">Careers</a>
                <br />
              </div>
              <div className="col-lg-3">
                <h6>CONTACT US</h6>
                <p>
                  3/4 Adewunmi Industrial Estate, <br />
                  Kudirat Abiola Way, Oregun, Lagos.
                </p>
                <p>
                  1390 Market, St, Suite 200, San Francisco, <br />
                  CA 94102 San Francisco.
                </p>
                <p>
                  <b>Email:</b> ​
                  <a href="mailto:hello@tradedepot.co">hello@tradedepot.co</a>
                  <br />
                  <b>Phone:</b> 0700 999 0000
                </p>
              </div>
            </div>

            <div className="trade-footer__line">
              © {new Date().getFullYear()} TradeDepot, Inc. All rights reserved.
              <p className="float-right">
                <a href="terms.html" className="mr-4">
                  Privacy
                </a>
                <a
                  href="https://help.tradedepot.co/"
                  target="_blank"
                  className="mr-4"
                  rel="noreferrer"
                >
                  Help Center
                </a>
                <a href="terms.html" className="mr-4">
                  Terms
                </a>
                <a href="terms.html">Acceptable Use</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
