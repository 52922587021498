/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Careers } from "./careers";
import { Culture } from "./culture";
import { LandingView as Supply } from "./landing";
import { LandingTwo } from "./landing2";
// import { Terms } from "./terms";
import lottie from "lottie-web";
import animationData from "./animation/circular.json";
import { LandingUk } from "./landinguk";
import { Layout } from "./layout";
import { PrivacyPolicies } from "./privacy";
import { UkShippingReturns } from "./shipping-returns";
import { ConfirmPayment } from "./checkout-screens/confirm-payment";
import { Auth } from "./checkout-screens/auth";
import "react-loading-skeleton/dist/skeleton.css";
import { Payment } from "./checkout-screens/pay";
import { OrderSummary } from "./checkout-screens/order-summary";
import { AddressInput } from "./checkout-screens/address";
import { VerifyPhone } from "./checkout-screens/verify-phone";
import { TermsConditions } from "./terms2";
import { KeyAccountTerms } from "./key-account-terms";

function Router() {
  const location = useLocation();

  const possibleRoutes = [
    "/",
    "/careers",
    "/culture",
    "/terms",
    "/landing-2",
    "/supply",
    "/en_ng",
    "/en_gb",
    "/privacy",
    "/returns",
    "/auth",
    "/wholesale/complete",
    "/wholesale/checkout",
    "/wholesale/confirm-payment",
    "/wholesale/pay",
    "/change-address",
    "/verify-phone",
    "/key-account-terms",
  ];

  useEffect(() => {
    if (
      window.location.href.endsWith("/") &&
      window.location.pathname.length > 1
    ) {
      return (window.location.href = window.location.href.slice(0, -1));
    }
    if (!possibleRoutes.includes(location.pathname)) {
      return (window.location.href = "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("circular"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* <Route index element={<LandingView />} /> */}
        <Route index element={<LandingUk />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/culture' element={<Culture />} />
        <Route path='/supply' element={<Supply />} />
        {/* <Route path='/terms' element={<Terms />} /> */}
        <Route path='/wholesale/confirm-payment' element={<ConfirmPayment />} />
        <Route path='/wholesale/checkout' element={<OrderSummary />} />
        <Route path='/wholesale/pay' element={<Payment />} />
        <Route path='/change-address' element={<AddressInput />} />
        <Route path='/verify-phone' element={<VerifyPhone />} />
        <Route path='/landing-2' element={<LandingTwo />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/en_gb' element={<LandingUk />} />
        <Route path='/en_ng' element={<LandingUk />} />
        <Route path='/terms' element={<TermsConditions />} />
        <Route path='/key-account-terms' element={<KeyAccountTerms />} />
        <Route path='/privacy' element={<PrivacyPolicies />} />
        <Route path='/returns' element={<UkShippingReturns />} />
      </Route>
    </Routes>
  );
}

export default Router;
