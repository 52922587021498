import React from "react";
import { CountryFilterPolicies } from "./components/country-filter-policies";
import { SUPPORTED_COUNTRIES } from "./utils/functions";
import { WebsiteFooter } from "./components/website-footer";
import { WebsiteHeader } from "./components/website-header";

export function PrivacyPolicies() {
  const qParameters = new URLSearchParams(window.location.search);
  const countryCode = qParameters.get("country") || SUPPORTED_COUNTRIES.GB;

  return (
    <>
      <div className=''>
        <div className='trade-uk-landing__intro'>
          <WebsiteHeader />
        </div>
        <div className='trade-uk-terms'>
          <p className='trade-uk-terms__title'>Privacy Policies</p>
          <div className='container'>
            <CountryFilterPolicies countryCode={countryCode} page='privacy' />
            {countryCode === SUPPORTED_COUNTRIES.NG ? (
              <NGPrivacyPolicies />
            ) : (
              <UKPrivacyPolicies />
            )}
          </div>
        </div>
        <WebsiteFooter isColored={true} countryCode={countryCode} />
      </div>
    </>
  );
}

const UKPrivacyPolicies = () => {
  return (
    <div className='trade-uk-terms__content'>
      <h3>1. INTRODUCTION</h3>
      <p>
        1.1 We take your privacy seriously. This Privacy Policy (together with
        our Terms and Conditions) sets out details about your privacy rights and
        how we gather, use and share your personal data – including the personal
        data we already hold about you now and any further personal data we
        might collect about you, either from you or from a third party when you
        use the TradeDepot Wholesale Website and Mobile app or become a customer
        or supplier of TradeDepot Wholesale.
        <br />
        <br />
        1.2 If you have any questions about how we use your personal data,
        please contact{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
      </p>
      <h3>2. About Us</h3>
      <p>
        2.1. TradeDepot Wholesale is operated by TradeDepot, the trading style
        of Frontier Africa Trading Co Ltd, a company registered in England,
        whose registered address is at 200 Brook Drive, Reading, United Kingdom
        RG2 6UB. Our company registration number is 14788482.
        <br />
        <br />
        2.2 TradeDepot is referred to as 'we', 'us' or 'our' in this Privacy
        Policy. Any use of the above terminology or other words in the singular,
        plural, capitalisation and/or he/she or they, are taken as
        interchangeable and therefore as referring to same.
      </p>
      <h3>3. Your Privacy Rights</h3>
      <p>
        3.1 In addition to the right to be informed about how we use your
        personal data (as set out in this Privacy Policy), you have various
        other rights in respect of the personal data we hold about you – these
        are set out in more detail below. If you wish to exercise any of these
        rights, please contact{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
        :
        <ul>
          <li>
            <b>Right to object</b>: You can object to our processing of your
            personal data where we are relying on a legitimate interest (or
            those of a third party) and there is something about your particular
            situation which makes you want to object to processing on this
            ground. You also have the right to object where we are processing
            your personal data for direct marketing purposes. Please contact us
            as noted above, providing details of your objection.
          </li>
          <li>
            <b>Access to your personal data</b>: You can request access to a
            copy of your personal data that we hold, along with information on
            what personal data we use, why we use it, who we share it with, how
            long we keep it for and whether it has been used for any automated
            decision-making.
          </li>
          <li>
            <b>Right to withdraw consent:</b>: If you have given us your consent
            to use your personal data, you can withdraw your consent at any
            time. In particular, if you have given us consent to send you
            marketing emails, you can withdraw your consent by clicking the
            "unsubscribe" link in any marketing email which you receive.
          </li>
          <li>
            <b>Rectification</b>: You can ask us to change or complete any
            inaccurate or incomplete personal data held about you.
          </li>
          <li>
            <b>Erasure</b>: You can ask us to delete your personal data where it
            is no longer necessary for us to use it, you have withdrawn consent,
            or where we have no lawful basis for keeping it. The account
            deletion process can be initiated under account settings on our
            mobile and web applications.
          </li>
          <li>
            <b>Portability</b>: You can ask us to provide you or a third party
            with some of the personal data that we hold about you in a
            structured, commonly used, electronic form, so it can be easily
            transferred.
          </li>
          <li>
            <b>Restriction</b>: You can ask us to restrict the personal data we
            use about you where you have asked for it to be erased or where you
            have objected to our use of it.
          </li>
          <li>
            <b>No automated decision-making</b>: Automated decision-making takes
            place when an electronic system uses personal data to make a
            decision without human intervention. You have the right not to be
            subject to automated decisions that will create legal effects or
            have a similar significant impact on you, unless you have given us
            your consent, it is necessary for a contract between you and us or
            is otherwise permitted by law. You also have certain rights to
            challenge decisions made about you. We do not currently carry out
            automated decision-making in relation to customers and suppliers,
            but we will notify you by updating this Privacy Policy if this
            changes.
          </li>
        </ul>
        3.2 We may need to verify your identity in order to process your request
        and ask you to provide valid identification documents to allow us to do
        so. Please note that we might use a third party to collect and validate
        your identification documents.
      </p>
      <h3>4. Information We Collect About You</h3>
      <p>
        Information you give us
        <br />
        <br />
        4.1 When you become a customer and register for an online customer
        account, we collect and use the following:
        <br />
        <ul className='alphas'>
          <li>full name;</li>
          <li>date of birth;</li>
          <li>
            home and business address, e-mail address and contact details;
          </li>
          <li> gender;</li>
          <li>
            a copy of your of Government issued id, proof of residency and a
            selfie of you;
          </li>
          <li>
            information about your business, business partners, beneficial
            owners or directors in which case you must ensure that you have
            permission to do this.
          </li>
        </ul>
        Other Information we collect
        <br />
        <br />
        4.2 We also collect and use the following personal data about you
        indirectly:
        <br />
        <ul className='alphas'>
          <li>
            if you are a credit customer at TradeDepot Wholesale, information
            obtained from any credit checks or other checks we are required to
            carry out for legal and regulatory purposes (see the section on
            'Anti Money Laundering/ Sanctions' below for further information);
          </li>
          <li>
            in order to administer the Website and understand how the Website is
            used, we collect technical information including your IP address,
            your login information, products you have viewed or searched,
            browser type, plug-ins you use, operating systems and platforms,
            URL, page response times, download errors, length of visits to
            certain pages, page interaction information, methods used to browse
            away from a page;
          </li>
          <li>
            information collected through the App, such as your purchases and
            account balance and geo-location data that identifies your location
            within the UK (you can change your settings at any time);
          </li>
          <li> any phone number used to contact us;</li>
        </ul>
      </p>
      <h3>5. How We Use Your Personal Data and Legal Basis</h3>
      <p>
        5.1 We use personal data held about you for the following purposes in
        line with our legitimate interests to administer the Website and the
        App, to engage with users when they request our products and services,
        and provide us with products and services and to protect and exercise
        our rights as a business:
        <br />
        <ul className='alphas'>
          <li>to respond to your queries;</li>
          <li>
            to manage any customer trading account with us and update the
            records we hold about you from time to time;
          </li>
          <li>
            for customers who hold credit accounts, to carry out credit checks;
          </li>
          <li>
            to provide you with your goods and information about your goods and
            our services and to provide you with information via post, email,
            telephone or SMS about other goods and services we offer that are
            similar to those you have already purchased or enquired about and
            that may be of interest to you, to make suggestions and
            recommendations to you about goods or services that may interest you
            (for further information, see the section on 'Keeping You Up to
            Date' below);
          </li>
          <li>
            to analyse your purchases and purchase behaviour so we can provide a
            better service to you and to keep our website safe and secure;
          </li>
          <li>
            we may anonymise the data we hold about you or your business and
            share it with our suppliers for their own reporting or marketing
            activities;
          </li>
          <li>
            if false or inaccurate information is provided and fraud is
            suspected or identified then this will be recorded and details will
            be passed to fraud prevention agencies. Law enforcement agencies may
            access and use this information. The information may also be used to
            prevent theft, fraud and money laundering for example, when checking
            details for customer registrations, credit applications or managing
            credit accounts;
          </li>
          <li>to administer events and hospitality.</li>
        </ul>
        5.2. We will also use your personal data, for example, your name,
        address and contact details, to carry out our contractual obligations to
        provide you with products or services which you have ordered or
        purchased from us and to contact you in relation to such products and
        services.
        <br />
        <br />
        5.3. As we are a regulated business, we are required by law to verify
        the identity of our customers and suppliers and carry out Anti Money
        Laundering and Sanctions checks.
        <br />
        <br />
        5.4 We will process your personal data, including your name, address and
        identity verification documents, for the purposes of preventing money
        laundering or terrorist financing, as it is necessary in order to
        prevent unlawful acts and for the purposes of meeting regulatory
        requirements.
        <br />
        <br />
        5.5 We are legally permitted to hold this information for no more than
        five years from the end of your business relationship with TradeDepot.
      </p>
      <h3>6. Special Categories of Personal Data</h3>
      <p>
        6.1 Special protection is given to certain kinds of personal data that
        is particularly sensitive. This is information about your health status,
        racial or ethnic origin, political views, religious or similar beliefs,
        sex life or sexual orientation, genetic or biometric identifiers, trade
        union membership ('special categories of personal data') or about your
        criminal convictions or offences.
        <br />
        <br />
        6.2 Where we collect any special categories of personal data, we will
        take appropriate steps to ensure that we have explicit consent or
        another legal basis to hold, use and retain the information. In
        particular, we collect special category personal data in relation to
        Anti Money Laundering and Sanctions checks and our legal basis for using
        this personal data is as set out at 5 above.
      </p>
      <h3>7. Disclosure of Your Personal Data</h3>
      <p>
        7.1 We may share your personal data in the following scenarios. Unless
        stated otherwise, we either do this in our in line with our legitimate
        interests to provide you with and engage with you in relation to our
        goods and services, the commercial interests of our suppliers, to
        generate legitimate business interest or for contractual purposes:
        <br />
        <ul className='alphas'>
          <li>
            selected third parties for the performance of our contractual
            obligations with you such as Delivery companies;
          </li>
          <li>
            with our suppliers for their supply chain management purposes (for
            example, your business name, address and purchase history), but not
            for their own marketing purposes unless you have indicated that you
            wish to receive such marketing;
          </li>
          <li>
            sub-contractors we may use in technical, payment and delivery
            services, events and hospitality;
          </li>
          <li>
            where you have agreed to receive marketing emails from us, we need
            to pass your personal data on to our service providers who help us
            with these marketing activities;
          </li>
          <li>
            analytics and search engine providers that assist us with
            improvement and optimisation of our site who may anonymise the
            information and send on to third parties for statistical purposes;
          </li>
          <li>
            credit reference agencies and other background check providers for
            Anti Money Laundering/ Sanctions purposes (see the section on 'Anti
            Money Laundering/ Sanctions' above for further information); and
          </li>
        </ul>
        7.2 Occasionally, we may also share personal data with relevant third
        parties:
        <br />
        <ul className='alphas'>
          <li>where you have given us your consent to do so;</li>
          <li>where we sell or buy any business or assets;</li>
          <li>
            if we are under a duty to disclose or share your personal data to
            comply with a legal obligation;
          </li>
          <li>
            for law enforcement purposes, including the prevention or detection
            of crime;
          </li>
          <li>
            to enforce or apply our Terms and Conditions and Shipping and
            Returns policy; or
          </li>
          <li>
            where it is fair and reasonable for us to do so in the
            circumstances.
          </li>
        </ul>
      </p>
      <h3>8. Where We Store Your Personal Data</h3>
      <p>
        8.1 We may need to transfer your information outside the UK to service
        providers, agents, subcontractors and regulatory authorities in
        countries where data protection laws may not provide the same level of
        protection as those in the European Economic Area ("EEA"), such as the
        USA.
        <br />
        <br />
        8.2 We will only transfer your personal data outside the EEA where
        either:
        <br />
        <ul className='alphas'>
          <li>
            the transfer is to a country which the EU Commission has decided
            ensures an adequate level of protection for your personal data; or
          </li>
          <li>
            we have put in place our own measures to ensure adequate security as
            required by data protection law. These measures include ensuring
            that your personal data is kept safe by carrying out strict security
            checks on our overseas partners and suppliers, backed by strong
            contractual undertakings approved by the relevant regulators such as
            the EU style model clauses. Some US providers may also be certified
            under the EU-US Privacy Shield which confirms they have appropriate
            measures in place to ensure the protection of your data.
          </li>
        </ul>
        8.3 If you would like further information, please contact{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
        .
      </p>
      <h3>9. Keeping You Up To Date</h3>
      <p>
        9.1 If you are a customer, we will communicate relevant news and
        information about products and services similar to those you have
        purchased or enquired about on the App, by post, email, telephone, SMS
        or other electronic messages, unless you tell us that you do not wish to
        receive them.
        <br />
        <br />
        9.2 You can unsubscribe at any time by clicking the
        <b>"unsubscribe"</b> link in any marketing email which you receive.
      </p>
      <h3>10. How Long We Hold Your Personal Data</h3>
      <p>
        10.1 We will not retain your personal data for any longer than is
        necessary for our purposes, including for the purposes of satisfying any
        legal, accounting or reporting requirements. If you have any questions
        about data retention then please email{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
        .
        <br />
        <br />
        10.2 Where we have been using your personal data to provide you with
        marketing, we will remove you from our marketing list if you ask us to
        do so, but we may still need to keep your information for ongoing
        contractual purposes if you continue to be our customer and for legal,
        accounting and regulatory reporting reasons.
      </p>
      <h3>11. Right to Complain</h3>
      <p>
        11.1. You can make a complaint to us by contacting us via{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>{" "}
        or to the data protection supervisory authority – in the UK, this is the
        Information Commissioner's Office, at{" "}
        <a href='https://ico.org.uk' target='_blank' rel='noreferrer'>
          https://ico.org.uk
        </a>
        .{" "}
      </p>
      <h3>12. Changes to this Privacy Policy</h3>
      <p>
        12.1. This Privacy Policy will be reviewed periodically and we will
        update it if we make any material changes to the manner in which we
        process and use your personal data.
      </p>
    </div>
  );
};

const NGPrivacyPolicies = () => {
  return (
    <div className='trade-uk-terms__content'>
      <h3>1. INTRODUCTION</h3>
      <p>
        1.1. TradeDepot (“TradeDepot” or “Us” or “We” or “Our”) is a retail
        technology company based in Lagos Nigeria with Our registered address at{" "}
        <b>
          3/4 Adewunmi Industrial Estate, Kudirat Abiola Way, Oregun, Lagos{" "}
        </b>
        . We are dedicated to protecting the confidentiality and privacy of
        personally identifiable information entrusted to Us (“Personal Data”).
        As part of that fundamental obligation, We are committed to the
        protecting the use of the Personal Data which We collect on{" "}
        <a href='https://tradedepot.co' target='_blank' rel='noreferrer'>
          https://tradedepot.co
        </a>{" "}
        (the “Website”). This Website helps you grow your retail business
        quickly by giving you cash advances to stock up your shop. The services
        We provide include the provisions of loans and all other services
        provided to you on your behalf through the Website from time to time
        (“Services”) This privacy policy (“Privacy Statement”) sets out the
        basis upon which We will process, store, and disclose any Personal Data
        that is provided voluntarily by users of the Website (“Data Subject” or
        “you” or “your”) in connection with the Services that We offer
        <br />
        <br />
        1.2. We note that the Nigeria Data Privacy Regulations (NDPR)
        distinguishes between entities that are “controllers” of Personal Data
        and entities that “process” Personal Data. We note that We are a “Data
        Controller” where We determine the purposes for and the manner in which
        Personal Data is processed or is to be processed and We are a
        “Processor” for those Services where We only use Personal Data which are
        requested by other Data Controllers.
        <br />
        <br />
        1.3. Where We make decisions on how Personal Data is used in connection
        with Our Services (where We are acting as a controller), We are
        responsible for the obligations of a “Controller” under the NDPR in
        connection with the processing of Personal Data – for example, We use
        this Privacy Statement and other notices to provide you with information
        about Our use of personal information, as required by the NDPR. Where We
        only use Personal Data requested by other Data Controllers (where We are
        acting as a processor), those other Data Controllers are similarly
        responsible for the obligations of a “Controller” under applicable laws
        in connection with the processing of personal information, and, if you
        are using Our Services through those other Controllers, you should
        contact them if you have questions or concerns about the processing of
        your personal information or compliance with the NDPR and other
        applicable laws.
        <br />
        <br />
        1.4. This Privacy Statement may change from time to time. Unless
        otherwise stated, any updates to this Privacy Statement become effective
        when We post the updates on the Website. Your continued use of the
        Website for Our Services following an update to the Privacy Statement
        means that you accept the updated Privacy Statement.
      </p>
      <h3>2. YOUR PRIVACY RIGHTS</h3>
      <p>
        2.1. This Privacy Statement describes your privacy rights regarding Our
        collection, use, storage, sharing and protection of your Personal Data.
        It applies to this Website and all other related applications, services,
        tools and physical contact with Us regardless of how you access or use
        them.
        <br />
        <br />
        2.2. You have the following rights:
        <br />
        <br />
        2.2.1. right to request from Us access to and rectification or erasure
        of your Personal Data;
        <br />
        <br />
        2.2.2. right to restrict processing or object to processing of your
        Personal Data;
        <br />
        <br />
        2.2.3. right to data portability;
        <br />
        <br />
        2.2.4. right to withdraw consent at any time, without affecting the
        lawfulness of processing based on consent before its withdrawal;
        <br />
        <br />
        2.2.5. right to lodge a complaint with the relevant authority.
        <br />
        <br />
        2.3. In the event that you wish to:
        <br />
        (i) access your Personal Data processed by Us in accordance with this
        Privacy Statement, or
        <br />
        (ii) ask any questions at all regarding Our use of your Personal Data,
        or
        <br />
        (iii) rectify your Personal Data processed by Us in accordance with this
        Privacy Statement
        <br />
        (iv) restrict or object to processing of your Personal Data
        <br />
        (v) withdraw your consent to Our processing of your Personal Data or
        <br />
        (vi) request for data portability , please reach out via email on{" "}
        <a href='mailto:support.ng@tradedepot.co'>support.ng@tradedepot.co.</a>
        <br />
        <br />
        2.4. We carefully analyze what types of Personal Data We need to provide
        Our Services, and We try to limit the Personal Data We collect to only
        what We really need.
        <br />
        <br />
        2.5. Where possible, We delete or anonymize Personal Data when We no
        longer need it. We will retain your Personal Data for the duration of
        Our Services to you and afterwards for as long as is necessary and
        relevant for purposes permitted by applicable laws and regulations.
        Where We no longer need your Personal Data, We will dispose of it in a
        secure manner.
        <br />
        <br />
        2.6. If you have created a username, identification code, password or
        any other piece of information as part of Our access security measures,
        you must treat such information as confidential, and you must not
        disclose it to any third party. We reserve the right to disable any user
        identification code or password, whether chosen by you or allocated by
        Us, at any time, if in Our opinion you have failed to comply with any of
        the provisions of these conditions. If you know or suspect that anyone
        other than you knows your security details, you must promptly notify Us
        at{" "}
        <a href='mailto:support.ng@tradedepot.co'>support.ng@tradedepot.co.</a>
      </p>
      <h3>3. YOUR CONSENT</h3>
      <p>
        3.1. Please read this Privacy Statement very carefully. If you do not
        wish to be bound by the provisions of this Privacy Statement, you should
        not access the Website or sign up for Our Services. By using the
        Website, and by signing up for Our Services, you hereby confirm that you
        have read, understand, and agree to be bound by the Privacy Statement.
        <br />
        <br />
        3.2. Additionally, please note that the option to sign up on this
        Website is intended solely for persons who are eighteen (18) years of
        age or older, and any registration by, use of or access to the Services
        by any person under 18 is unauthorized and in violation of this Privacy
        Statement.
        <br />
        <br />
        3.3. You hereby give Us consent to the collection and processing of your
        Personal Data for the purpose of providing the Services. You hereby
        certify that all the information provided by you are true and correct
        and that any misrepresentations or falsity, whether intentional or not,
        therein will be considered as an act to defraud Us and Our partners. You
        authorize Us to verify and investigate the above statements/information
        as may be required, from the references provided and other reasonable
        sources. For this purpose, you hereby waive your rights on the
        confidentiality of client information and expressly consent to the
        processing of any Personal Data and records relating to you that might
        be obtained from third parties, including government agencies, your
        employer, credit bureaus, business associates, and other entities We may
        deem proper and sufficient in the conduct of your business, sensitive or
        otherwise, for the purpose of determining your eligibility for a loan
        for which you are applying. You further agree that when you request for
        the Services or apply for a loan, all supporting documents and any other
        information obtained relative to such application shall be used by and
        communicated to Us, and shall remain Our property whether or not your
        credit score is determined, or the loan is granted. For the purpose of
        providing the Services to you, you expressly and unconditionally
        authorize Us to disclose to any bank, partners, affiliate, accredited
        credit reporting agencies. and other financial institution, any Personal
        Data relating to you.
      </p>
      <h3>4. WHAT KIND OF PERSONAL DATA DO WE COLLECT?</h3>
      <p>
        4.1. This section details the Personal Data We may collect about you. We
        explain why, and how, We use it in later sections. If you decide to
        create an account or enquire about one of Our products or Services,
        We’ll ask You to provide Us with some specific information, for example:
        Basic personal details – such as name, surnames, passport photograph,
        title and date of birth; Personal contact details – such as home
        address, delivery address, home phone number, mobile phone number, and
        email address; Work details – such as job title, department, company
        name, company address, personal information of employees who may act as
        your agents, work email address and office phone number; Log in details
        – such as username and password; Usage Information and Browsing History
        - such as usage metrics (including usage rates, occurrences of technical
        errors, diagnostic reports, settings preferences, content and
        advertising interactions; banking details –account number, bank
        verification number. We may also collect additional information
        involving Your opinion of Our products and Services and Your preferences
        regarding other services such as newsletter subscription.
      </p>
      <h3>5. How We Use Your Personal Data and Legal Basis</h3>
      <p>
        5.1. We collect Personal Data about you either directly from You, from
        certain third parties (such as your employer or the subscriber providing
        access to Our Services), or from other sources (such as publicly
        available sources) when you
        <br />
        <ul className='alphas'>
          <li>Search for, subscribe to Our Services.</li>
          <li>Use Our Services or otherwise interact with Us.</li>
          <li>Create or maintain a profile or account with Us.</li>
          <li>
            Purchase, use, or otherwise interact with content, products, or
            services from third party providers who have a relationship with Us.
          </li>
          <li>Create, post, or submit user content on Our Services.</li>
          <li>Register for or attend one of Our events or locations.</li>
          <li>Request or sign up for information.</li>
          <li>
            Communicate with Us by phone, email, chat, in person, or otherwise.
          </li>
          <li>
            Complete a questionnaire, survey, support ticket, or other
            information request form.
          </li>
          <li>
            When you express an interest in working with Us or apply for a job
            or position with Us.
          </li>
        </ul>
        5.2. In some circumstances, We collect Personal Data about you from Our
        partners or from publicly available websites to help Us better
        understand Our audience and enhance the relevance of Our content. We
        collect Personal Data about you from third parties, such as:
        <br />
        <ul className='alphas'>
          <li>
            Your organization and others with whom you have a relationship that
            provide or publish Personal Data related to you, such as from Our
            customers when they arrange access to Our Services for you or from
            others when they create, post, or submit user content on Our
            Services that may include your Personal Data.
          </li>
          <li>
            Professional or industry organizations and certification / licensure
            agencies that provide or publish Personal Data related to you.
          </li>
          <li>
            Third parties and affiliates who resell or integrate with Our
            Services.
          </li>
          <li>
            Service providers and business partners who work with Us in relation
            to Our Services and that We may utilize to deliver certain content,
            products, or services or to enhance your experience.
          </li>
          <li>
            Marketing, sales generation, and recruiting business partners.
          </li>
          <li>Credit bureaus and other similar agencies.</li>
          <li>
            Government agencies and others who release or publish public
            records.
          </li>
          <li>
            Other publicly or generally available sources, such as social media
            sites, public and online websites, open databases, the corporate
            affairs commission, and data in the public domain.
          </li>
        </ul>
        <br />
        <br />
        5.3. We use cookies (and similar technologies) and analytics tools to
        collect information about You. This information can be used to improve
        the performance of the site, make advertising more relevant and enhance
        your user experience, such as Your location – We use cookies to display
        information that is most relevant to your location. Your usage – We use
        cookies to understand how Our customers use Our Website and interact
        with Our communications. For example, We use technology on Our Website,
        which records user movements, including page scrolling, clicks and text
        entered. (It does not record payment details.) This helps Us to identify
        usability issues and improve the assistance We can provide to users and
        is also used for aggregated and statistical reporting purposes. Your
        device – We use cookies to understand what type of device you are using
        to show you the best version of the site. For instance, if you visit Our
        Website on a mobile device, Our technology will ensure that you see a
        version that is optimized for mobile viewing and interaction.Your
        engagement with advertisements – We use cookies to understand what
        advertisements you have been shown, or clicked on, to bill Our
        advertising partners, and to present you with advertisements that are
        more relevant to you.
        <br />
        <br />
        5.4 Cookies are small files placed on your computer’s hard drive that
        enables the Website to identify your computer as you view different
        pages. Cookies allow websites and applications to store your preferences
        in order to present contents, options or functions that are specific to
        you. Like most interactive websites, Our Website uses cookies to enable
        the tracking of your activity for the duration of a session.
      </p>
      <h3>6. LAWFUL BASIS FOR PROCESSING</h3>
      <p>
        6.1 We only process personal data where we have a lawful basis for doing
        so, such as the following:
        <br />
        <br />
        6.1.1. <b>User consent -</b> This is where you have given Us explicit
        permission to process Personal Data for a given purpose. For example, if
        you create a profile on Our Website, We may additionally ask you to
        consent to Us processing your Personal Data.
        <br />
        <br />
        6.1.2. <b>Contractual necessity –</b> This is where We have to process
        Personal Data to meet Our contractual obligations.
        <br />
        <br />
        6.1.3. <b>Legal obligation –</b> This is where We have to process
        Personal Data in order to comply with the law.
        <br />
        <br />
        6.1.4. <b>Protection of vital interests –</b> This is where We are
        constrained to process your Personal Data in order to protect your
        interests or those of another person, such as to prevent identity theft.
        <br />
        <br />
        6.1.5. <b>Public interest –</b> This is where there is an overriding
        public interest or We have been vested with an official public mandate
        to take certain action in the interest of the public.
        <br />
        <br />
        6.1.6. <b>Legitimate/Commercial interest -</b> This is where We process
        Personal Data in order to protect Our legitimate/commercial interest.
      </p>
      <h3>7. HOW DO WE USE YOUR PERSONAL DATA?</h3>
      <p>
        7.1. TradeDepot acts as a Data Controller when using your Personal Data
        in providing you with Services. We use Personal Data about you in the
        following ways:
        <br />
        <br />
        7.1.1. to provide you access to Our Services;
        <br />
        <br />
        7.1.2. to improve and maintain performance of the Website, by ensuring
        that the content on the Website is presented in the most effective
        manner for you;
        <br />
        <br />
        7.1.3. to provide you with data about services that you request from Us
        or which We feel may interest you, where you have consented to be
        contacted for such purposes;
        <br />
        <br />
        7.1.4. for statistical analysis or market research;
        <br />
        <br />
        7.1.5. to develop and improve Our Services;
        <br />
        <br />
        7.1.6. to update Our records;
        <br />
        <br />
        7.1.7. to detect, investigate, prevent, or tackle illegal activities,
        fraud, or situations involving potential threats to the rights,
        property, or personal safety of Data Subject;
        <br />
        <br />
        7.1.8. to ensure Our compliance with Our policies, terms, and other
        applicable laws and regulations;
        <br />
        <br />
        7.1.9. to carry out Our obligations arising from and exercise Our rights
        under any agreements between you and Us;
        <br />
        <br />
        7.1.10. to allow you to participate in interactive features of our
        Services, when you choose to do so;
        <br />
        <br />
        7.1.11. to notify you about changes to Our Services;
        <br />
        <br />
        7.1.12. to provide you with customer service;
        <br />
        <br />
        7.1.13. for any legitimate business Interest, including recovery of
        loans that are in default;
        <br />
        <br />
        7.1.14. recruitment – If you apply for a role via one of Our sites, We
        will process your information in order to facilitate the application
        process;
        <br />
        <br />
        7.1.15. to process and assess your application for employment with Us;
        and
        <br />
        <br />
        7.1.16. in any other way that you have specifically consented to.
      </p>
      <h3>8. WHO WE SHARE YOUR PERSONAL DATA WITH?</h3>
      <p>
        8.1. We do not share personal data with unaffiliated third parties,
        except as necessary for our legitimate business interest, to provide you
        with our services, and/or as required or permitted by law or
        professional standards and generally to facilitate the running of our
        business or to provide specific services you have requested. We would
        only transfer personal data to the following categories or situations,
        when they meet our strict standards on the processing of data and
        security. We only share personal data that is necessary for them to
        provide their services. TradeDepot will not transfer your personal data
        to any third parties for their own direct marketing use except as stated
        herein. Commonly, we may share your personal data with:
        <br />
        <br />
        8.1.1. <b>Service providers –</b> We engage service providers who help
        to support Our business and improve Our products. These service
        providers may include payment processors, customer service agencies,
        credit bureaus, hosts, organizers and sponsors of Our events;
        organizations that host Our Website or databases and providers of online
        surveys. We have contracts in place with these data processors and they
        can only use your Personal Data under Our instruction.
        <br />
        <br />
        8.1.2. <b>Advertisers –</b> We, and Our advertising partners, use
        cookies to collect Personal Data and serve you with advertisements that
        We believe are relevant to you and your interests.
        <br />
        <br />
        8.1.3. <b>Audits -</b> disclosures of Personal Data may also be needed
        for data privacy, financial or security audits and/or to investigate or
        respond to a complaint or security threat.
        <br />
        <br />
        8.1.4. <b>Legal processes and successors in title –</b> We, and Our
        suppliers, may also disclose your Personal Data to comply with
        applicable laws, court orders or other valid legal processes, and to
        enforce or apply Our terms of use, any subscription agreement, or any of
        Our other rights. We may transfer or disclose your Personal Data to any
        entity which takes over or acquires the relevant TradeDepot company. We
        also share aggregated, non-identifiable information with third parties.
        Before sharing this information, We ensure that it cannot identify you
        as an individual. For example, We may share information about the size
        and growth of Our customer base with investors.
        <br />
        <br />
        8.1.5. <b>Other third parties/relatives or acquaintances -</b> We may
        disclose your Personal Data to other third parties in order to compel
        you to meet your contractual obligations to Us. For example, in the
        event of a default in the repayment of a loan advanced to you, We may
        share your Personal Data with debt recovery agencies, debt collectors,
        your acquaintances or within your personal network.
      </p>
      <h3>9. HOW LONG WE RETAIN YOUR PERSONAL DATA?</h3>
      <p>
        9.1. We retain your Personal Data for as long as your account is active
        and therefore open; this allows you to continue accessing Our content,
        products and services.
        <br />
        <br />
        9.2. If you become inactive and are not a subscriber, We retain your
        Personal Data only for the period within which it is reasonably needed
        and may delete elements of your Personal Data associated with your
        account. We will normally warn you if your access is going to be
        withdrawn and give you the opportunity to retain your account.
        <br />
        <br />
        9.3. Where We don’t need to keep all of your Personal Data in full, We
        may obfuscate or aggregate it, for example, web activity logs and survey
        responses. This is to ensure that We do not retain your Personal Data
        for any longer than is necessary.
        <br />
        <br />
        9.4. It is sometimes necessary for us to keep your personal data for
        longer periods of time, for example:
        <br />
        <br />
        9.4.1. If there is a statutory requirement to retain it;
        <br />
        <br />
        9.4.2. If We require the Personal Data for legal reasons or there is a
        legitimate business need for Us to retain it;
        <br />
        <br />
        9.4.3. To ensure We do not contact you if you have asked Us not to.
      </p>
      <h3>10. HOW DO WE SECURE AND STORE PERSONAL DATA?</h3>
      <p>
        10.1. We employ all reasonable efforts to keep your Personal Data secure
        by taking appropriate technical and organizational measures against its
        unauthorised or unlawful processing and against its accidental loss,
        destruction or damage. We store and process your Personal Data on Our
        computers in Nigeria. Where We need to transfer your Personal Data to
        another country, We would take reasonable steps to ensure that such
        country has an adequate data protection law. You hereby consent to the
        transfer of your Personal Data to countries which may not be on the
        white list specified by the NDPR where such transfers become expedient
        for us. We protect your Personal Data using physical, technical, and
        administrative security measures to reduce the risks of loss, misuse,
        unauthorized access, disclosure and alteration. Some of the safeguards
        We use are firewalls and data encryption, physical access controls to
        Our data centers, and information access authorization controls.
      </p>
      <h3>
        11. WHAT HAPPENS WHEN YOU PROVIDE US WITH INFORMATION ABOUT OTHERS?
      </h3>
      <p>
        11.1. If you provide Us with Personal Data about another individual, you
        are responsible for ensuring that you comply with any obligation and
        consent obligations under applicable data protection laws in relation to
        such disclosure. Insofar as required by applicable data protection laws,
        you must ensure that you have provided the required notices and have
        obtained the individual’s explicit consent to provide Us with the
        information and that you explain to them how We collect, use, disclose
        and retain their Personal Data or direct them to read Our Privacy
        Statement
      </p>
      <h3>12. HOW DO WE COLLECT AND STORE CONTACT INFORMATION?</h3>
      <p>
        12.1. When you choose to upload your contact list, we may collect only
        the phone numbers and do not store any contact information contained in
        your list. This information will be used solely for the purpose of
        providing you with the features and services of our platform, including
        the ability to send airtime to a contact. 13. For general enquiries
        regarding this Privacy Statement, or to make a request or complain about
        how We process Personal Data, you may contact Us via email at{" "}
        <a href='mailto:support.ng@tradedepot.co'>support.ng@tradedepot.co.</a>
      </p>
      <h4 className='last-modified'>Last Modified: 27th Feb, 2024</h4>
    </div>
  );
};
