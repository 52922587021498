import { useState } from "react";
import { SUPPORTED_COUNTRIES } from "../utils/functions";

export function CountryFilterPolicies({ countryCode, page }) {
  const [country] = useState(countryCode);

  const handleChangeCountry = (code) => {
    if (!page) return;
    const url = `/${page}?country=${code}`;
    window.location.replace(url);
  };
  return (
    <div className='filter-country'>
      <label>Select Country</label>
      <select
        className='form-control'
        value={country}
        onChange={(e) => handleChangeCountry(e.target.value)}
      >
        <option value={SUPPORTED_COUNTRIES.NG}>Nigeria</option>
        <option value={SUPPORTED_COUNTRIES.GB}>United Kingdom</option>
      </select>
    </div>
  );
}
