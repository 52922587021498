import { useEffect, useState } from "react";
import { AppHeader } from "../components/app-header";
import axios from "axios";

function VerifyEmail({ goToNextPage }) {
  return (
    <div className='trade-uk_signup'>
      <div className=''>
        <h3>Verify your email</h3>
        <p>
          We've sent a verification code to the email you provided. Please enter
          the code below.
        </p>
      </div>
      <div className='mt-3 mb-3'>
        <label>Enter Code</label>
        <input placeholder='000000' className='form-control' />
      </div>
      <p>
        Not getting a code? <span>Resend Code</span>{" "}
      </p>
      <button className='trade-uk-app-button' onClick={goToNextPage}>
        Verify
      </button>
    </div>
  );
}

function Register({ goToNextPage }) {
  return (
    <div className='trade-uk_signup'>
      {/* <i className='fa fa-close'></i> */}
      <div className=''>
        <h3>Get Started</h3>
        <p>Enter your email address to continue</p>
      </div>
      <div className='mt-3'>
        <label>Email address</label>
        <input placeholder='Enter your email' className='form-control' />
      </div>
      <button className='trade-uk-app-button' onClick={goToNextPage}>
        Continue
      </button>
    </div>
  );
}

function ProfileDetails({ goToNextPage, saveSelectedAddress }) {
  const [inputAddressManually, setInputAddressManually] = useState(false);
  const [addressContainers, setAddressContainers] = useState([]);
  const [storeAddress, setStoreAddress] = useState("");
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [step, setStep] = useState(1); //1 - address containers, 2 - address list, 3 - retrieve address

  useEffect(() => {
    if (!storeAddress.length) return;
    const runFunc = setTimeout(async () => {
      setLoadingAddress(true);
      try {
        const {
          data: { data },
        } = await axios.get(
          `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/getAddress?country=GB&postCode=${storeAddress}`
        );
        setAddressContainers(data);
        setLoadingAddress(false);
      } catch (error) {
        console.log("handle search address err", error);
        setLoadingAddress(false);
      }
    }, 2000);
    return () => clearTimeout(runFunc);
  }, [storeAddress]);

  const handleFetchAddressFromContainer = async (container) => {
    console.log({ container });
    setStep(2);
    setAddressContainers([]);
    setLoadingAddress(true);
    const id = container.Id;
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/getAddress?country=GB&postCode=${container.Text}&type=addressFind&id=${id}`
      );
      console.log({ data });
      setAddresses(data);
      setLoadingAddress(false);
    } catch (error) {
      console.log("sds", error);
      setLoadingAddress(false);
    }
  };

  const handleRetrieveAddress = async (address) => {
    console.log("selected address", address);
    setStep(3);
    setAddresses([]);
    setLoadingAddress(true);
    const id = address.Id;
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/getAddress?country=GB&postCode=${address.Text}&type=addressRetrieve&id=${id}`
      );
      saveSelectedAddress(data);
      setLoadingAddress(false);
    } catch (error) {
      console.log("sds --- retrieve address", error);
      setLoadingAddress(false);
    }
  };

  return (
    <div className='trade-uk_signup profile'>
      <div className=''>
        <h3>Profile Details</h3>
        <p>Complete your details to create your profile</p>
      </div>
      <div className='mt-3 mb-3'>
        <label>First name*</label>
        <input placeholder='Enter your first name' className='form-control' />
      </div>
      <div className='mt-3 mb-3'>
        <label>Last name*</label>
        <input placeholder='Enter your last name' className='form-control' />
      </div>
      <div className='mt-3 mb-3'>
        <label>Gender*</label>
        <select
          className='form-control'
          defaultValue={"Select Gender"}
          defaultChecked='Select Gender'
          placeholder='Select Gender'
        >
          <option disabled>Select Gender</option>
          <option>Male</option>
          <option>Female</option>
        </select>
      </div>
      <div className='mt-3 mb-3'>
        <label>Store name*</label>
        <input placeholder='Enter store name' className='form-control' />
      </div>
      <div className='mt-3 mb-3'>
        <label>Store type*</label>
        <select
          className='form-control'
          defaultValue={"Select store type"}
          defaultChecked='Select store type'
          placeholder='Select store type'
        >
          <option disabled>Select store type</option>
          <option>Male</option>
          <option>Female</option>
        </select>
      </div>
      {!inputAddressManually && (
        <div className='mt-3 mb-3 auto-search-address'>
          <label>Store address*</label>
          <input
            placeholder='Type your address'
            className='form-control'
            onChange={({ target: { value } }) => setStoreAddress(value)}
          />
          {loadingAddress && (
            <div
              class='spinner-border spinner-border-sm address-loader'
              role='status'
            >
              <span class='sr-only'>Loading...</span>
            </div>
          )}
          <AddressListContainer
            addressContainers={addressContainers}
            handleFetchAddressFromContainer={handleFetchAddressFromContainer}
            addresses={addresses}
            handleRetrieveAddress={handleRetrieveAddress}
            step={step}
          />
          <h6
            className='address-link'
            onClick={() => setInputAddressManually(!inputAddressManually)}
          >
            Enter address manually
          </h6>
        </div>
      )}
      {inputAddressManually && (
        <>
          <h6
            className='address-link'
            onClick={() => setInputAddressManually(!inputAddressManually)}
          >
            Search address
          </h6>
          <div className='mt-3 mb-3'>
            <label>Address line 1*</label>
            <input
              placeholder='Street address, Floor, Unit, Building'
              className='form-control'
            />
          </div>
          <div className='mt-3 mb-3'>
            <label>Address line 2*</label>
            <input placeholder='Address line 2' className='form-control' />
          </div>
          <div className='mt-3 mb-3'>
            <label>Town/City*</label>
            <input placeholder='Type your town' className='form-control' />
          </div>
          <div className='mt-3 mb-3'>
            <label>Post code*</label>
            <input placeholder='Type postcode' className='form-control' />
          </div>
          <div className='mt-3 mb-3'>
            <label>Country *</label>
            <input
              placeholder='Type your town'
              value='United Kingdom'
              disabled
              className='form-control'
            />
          </div>
        </>
      )}
      <button className='trade-uk-app-button' onClick={goToNextPage}>
        Continue
      </button>
    </div>
  );
}

function AddressListContainer({
  addressContainers = [],
  handleFetchAddressFromContainer,
  handleRetrieveAddress,
  addresses = [],
  step = 1,
}) {
  console.log({ step, addressContainers, addresses });
  const RenderAddressContainerList = () => {
    return addressContainers.map((el) => (
      <p className='' onClick={() => handleFetchAddressFromContainer(el)}>
        {el.Text} <span>{el.Description}</span>
      </p>
    ));
  };
  const RenderAddressList = () => {
    return addresses.map((el) => (
      <p className='' onClick={() => handleRetrieveAddress(el)}>
        {el.Text} <span>{el.Description}</span>
      </p>
    ));
  };

  return addresses.length || addressContainers.length ? (
    <div className='address-list-container'>
      {addressContainers.length ? (
        <RenderAddressContainerList />
      ) : addresses.length ? (
        <RenderAddressList />
      ) : null}
    </div>
  ) : null;
}

export function Auth() {
  const [page, setPage] = useState(3);
  const [selectedAddress, setSelectedAddress] = useState({});
  console.log({ selectedAddress });
  // const [data, setData] = useState({
  //   name: "", // storename,
  //   country: "", // selectedAddress.Iso2
  //   lga: "", // storeaddress.lga
  //   landmark: "", //storeAddress[landmark]
  //   gender: "",
  //   user: { firstname: "", lastname: "" },
  //   outletTypeId: "", //store["type"],
  //   domain: "shoptopup",
  //   // extChannel: getExtChannel(),
  //   // referralCode: user["referralCode"],
  //   email: "",
  //   address: {
  //     company: "", //store['name'],
  //     fullName: "", // user["firstName"] + " " + user["lastName"],
  //     // 'address': storeAddress.address,
  //     // 'city': storeAddress.city,
  //     // 'lga': storeAddress.lga,
  //     // 'state': storeAddress.state,
  //     // 'country': storeAddress.countryCode,
  //     // 'postal': storeAddress.postal,
  //     // if (storeAddress.latitude != null && storeAddress.longitude != null)
  //     //   'coordinates': {
  //     //     'latitude': storeAddress.latitude,
  //     //     'longitude': storeAddress.longitude,
  //     //   },
  //   },
  // });

  // const handleVerifyEmail = () => {
  //   try {
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  // const handleConfirmVerificationCode = () => {
  //   try {
  //   } catch (error) {}
  // };

  const completeSignUp = () => {
    try {
    } catch (error) {}
  };

  return (
    <>
      <AppHeader />
      {page === 1 ? (
        <Register goToNextPage={() => setPage(2)} />
      ) : page === 2 ? (
        <VerifyEmail goToNextPage={() => setPage(3)} />
      ) : page === 3 ? (
        <ProfileDetails
          goToNextPage={completeSignUp}
          saveSelectedAddress={(el) => setSelectedAddress(el)}
        />
      ) : null}
    </>
  );
}
