const LS_USER = "td-shop-user";
const LS_RETAIL_OUTLET = "td-shop-outlet";
const LS_CART_ORDERS = "cart-orders";
const LS_PREPARED_CART_ORDERS = "prepared-orders";

export const handleUserSave = (user) => {
  return localStorage.setItem(LS_USER, JSON.stringify(user));
};

export const handleRetailOutletSave = (retailOutlet) => {
  return localStorage.setItem(LS_RETAIL_OUTLET, JSON.stringify(retailOutlet));
};

export const handleLSCartOrderSave = (cart) => {
  return localStorage.setItem(LS_CART_ORDERS, JSON.stringify(cart));
};

export const handlePreparedOrderSave = (order) => {
  return localStorage.setItem(LS_PREPARED_CART_ORDERS, JSON.stringify(order));
};

export const handleRetrieveCartOrders = () => {
  return JSON.parse(localStorage.getItem(LS_CART_ORDERS));
};

export const handleRetrieveUser = () => {
  return JSON.parse(localStorage.getItem(LS_USER));
};

export const handleRetrieveRetailOutlet = () => {
  return JSON.parse(localStorage.getItem(LS_RETAIL_OUTLET));
};

export const clearLS = (leaveUser = false) => {
  localStorage.removeItem(LS_RETAIL_OUTLET);
  localStorage.removeItem(LS_CART_ORDERS);
  localStorage.removeItem(LS_PREPARED_CART_ORDERS);
  if (!leaveUser) localStorage.removeItem(LS_USER);
  return;
};

export const isUserAuthenticated = () => {
  const user = JSON.parse(localStorage.getItem(LS_RETAIL_OUTLET));
  if (user) return true;
  return false;
};

export const calculateOrderTotal = (cart) => {
  let cartTotal = 0;
  if (cart?.order?.orders?.length) {
    const total = cart.order.orders.reduce((acc, cum) => {
      return acc + cum.total;
    }, 0);
    cartTotal = total;
  }
  return { cartTotal: cartTotal.toFixed(2) };
};

export const printErrorMessage = (
  error,
  intent = "",
  useServerMessage = false
) => {
  const errorRes = error?.response;
  const message = errorRes?.data?.data?.message || errorRes?.data?.message;

  // check if there is active internet
  if (!navigator.onLine)
    return {
      title: "Network Error",
      message:
        "Please, get an active internet connection to use this service and refresh.",
    };
  // return server message
  if (useServerMessage) return { title: "Unknown Error!", message: message };
  // intent is for message construction
  if (errorRes && errorRes?.status === 401 && intent === "auth-token")
    return {
      title: "Authorization Error",
      message:
        "Unfortunately, this token is invalid for use. Please, reach out to the business for help!",
    };
  return {
    title: "Error!",
    message,
  };
};

export const remedyProductError = (errorCode) => {
  if (errorCode === "BELOW_MDV") return "Add more items.";
  return "";
};

export const verifyPaymentError = (errorCode) => {
  const authCode = [
    4500, 4501, 4502, 4504, 4505, 4506, 4507, 4508, 4510, 4511, 4516, 4517,
    4518, 4524, 4525, 4527, 4528, 4529, 4530, 4531, 4532, 4535, 4536, 4537,
    4538, 4539, 4540, 4541, 4542, 4543, 4544, 4546,
  ];
  const incorrectCode = [4512, 4513, 4514, 4519, 4520, 4521, 4522, 4523];
  if (authCode.includes(errorCode)) {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment. Please contact your bank for more details.",
    };
  }
  if (errorCode === "4503") {
    return {
      title: "Card not Supported",
      message:
        "We are unable to process your payment because your card is not supported. Please try a different card or contact your bank for more information.",
    };
  }
  if (errorCode === "4509") {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment because your card is expired. Please verify or update your card details and try again. If your card information is correct, please contact your bank for more details.",
    };
  }
  if (incorrectCode.includes(errorCode)) {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment because your card details are incorrect. Please verify or update your card details and try again. If your card information is correct, please contact your bank for more details.",
    };
  }
  if (errorCode === "4515") {
    return {
      title: "Payment Declined",
      message:
        "We are having trouble authorising your payment because your address details are incorrect. Please verify or update your address details and try again. If your address information is correct, please contact your bank for more details.",
    };
  }
  if ([4526, 4534, 4545, 4547].includes(errorCode)) {
    return {
      title: "Unable to process ",
      message:
        "We are unable to process your payment at the moment. Please try again later.",
    };
  }
  if (errorCode === "4533") {
    return {
      title: "Incorrect Pin",
      message:
        "You have entered the wrong pin too many times. Please contact your bank for more information.",
    };
  }
  if (errorCode === "4526") {
    return {
      title: "Unable to process",
      message:
        "We are unable to process your payment at the moment. Please try again later.",
    };
  }
  if (errorCode === API_CUSTOM_CODE) {
    return {
      title: "Payment Failed",
      message:
        "Apologies, there was a problem processing your order. Your account won't be charged. Please attempt the transaction again.",
    };
  }
  return {
    title: "Payment Failed",
    message:
      "Your payment has failed and we don't seem to know why! Please reach out to the help center to help you",
  };
};

export const ITEMS_OUT_OF_STOCK = "ITEMS_OUT_OF_STOCK";
export const formatMoney = (value) => {
  return new Intl.NumberFormat("en-US", {}).format(value);
};
export const API_CUSTOM_CODE = 1252;
export const UK_WHATSAPP_LINE = process.env.REACT_APP_UK_WHATSAPP_URL;
export const NG_WHATSAPP_LINE = process.env.REACT_APP_NG_WHATSAPP_URL;
export const SHOP = { MOBILE: "SHOP.MOBILE" };
export const PAYMENT = {
  WALLET: "wallet",
  CREDIT: "credit",
};
export const SUPPORTED_COUNTRIES = { NG: "NG", GB: "GB" };
